import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const DropDownArray = ({options, select, handleSelect, name, width}) => {

    const classes = useStyles();
    const {t} = useTranslation();
    
    function valueCreator(item){
        let itemValue = ''
        options.map(
            i=>{if(JSON.stringify(i[0])===JSON.stringify(item)) itemValue = i[1]})
        return t(`${itemValue}`)
    }

    return (
        <div>
            {options && (<FormControl className={classes.formControl} style={{width: width}}>
                <InputLabel id="demo-simple-select-label">{t(`${name}`)}</InputLabel>
                <Select 
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select} 
                    renderValue={()=>valueCreator(select)}
                    onChange={(e)=>handleSelect(e.target.value)}
                >
                    {options.map(item=>(
                        <MenuItem value={item[0]} key={item}>{t(`${valueCreator(item[0])}`)}</MenuItem>
                    ))}
                </Select>
            </FormControl>)}
        </div>
    )
}

export default DropDownArray
