import { Grid } from '@material-ui/core'
import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';

const Feature = ({feature, status}) => {
    const {t} = useTranslation()
    return (
        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'space-between'}}>
            {t(`${feature}`)}<span>{status==="true"?<CheckCircleIcon style={{color: 'green'}} />:<ClearIcon style={{color: 'red'}} />}</span>
        </Grid>
    )
}

export default Feature
