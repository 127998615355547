import React, {useState} from 'react'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import Ad from './ad/Ad'
import useStyles from './styles'

// import one from '../../assests/images/testAd/one.jpg';
import { useTranslation } from 'react-i18next';

const Ads = ({
    advertisements,
    user,
    admin,
    deleleItem=null,
    userFav=false,
    deleleFavItem=null,
    page,
    setPage,
    adsCount,
    noPagination=false,
    isLoading=false}) => {

    const {t} = useTranslation();
    const styles = useStyles();

// User part
    const [delAlert, setDelAlert] = useState(false)
    const [delId, setDelId] = useState('')
    const deleteAd = (id) => {
        setDelAlert(false)
        if(id){
            setDelId(id)
            setDelAlert(true)
        }
    }
    function DeleteAlert(){
        return(
            <div className={styles.deleteAlertDiv}>
                <div className={styles.deleteAlertContent}>
                    <p className={styles.deleteAlertMsg}>{t(`are_you_sure_to_delete_this_advertise`)}</p>
                    <div className={styles.deleteAlertBtns}>
                        <Button variant='contained' color='secondary' onClick={()=>{setDelAlert(false); deleleItem(delId); setDelId('')}}>{t(`yes`)}</Button>
                        <Button variant='contained' color='primary' onClick={()=>{setDelId(''); setDelAlert(false)}}>{t(`no`)}</Button>
                    </div>
                </div>
            </div>
        )
    }
// End of user part

    function handlePage(e, val){
        setPage(val)
    }

    return (
        <React.Fragment>
            {delAlert && <DeleteAlert />}
            <Grid container spacing={4} className={styles.gridRoot} >
                {advertisements.map((ad)=>(
                    <Grid item key={ad.id} xs={12} md={4} sm={6} lg={3} style={{padding:13}}>
                        <Ad 
                            id={ad._id}
                            title={ad.title}
                            price={ad.price} 
                            description={ad.description} 
                            // image={one}
                            adsFiles={ad.adsFiles}
                            city={ad.city} 
                            neighborhood={ad.neighborhood} 
                            kindOfProperty={ad.kindOfProperty}
                            bed={ad.bed}
                            date={ad.date}
                            size={ad.size}
                            status={ad.status}
                            user={user}
                            admin={admin}
                            deleteAd={deleteAd}
                            userFav={userFav}
                            deleleFavItem={deleleFavItem}
                        />
                    </Grid>
                ))}
            </Grid>
            {(!noPagination && advertisements.length) && <Grid container style={{marginBottom: '40px', justifyContent: 'center'}}>
                <Pagination count={adsCount} page={page} color="primary" onChange={handlePage} />
            </Grid>}
        </React.Fragment>
    )
}

export default Ads