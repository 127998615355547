import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const ImageSlider = ({images}) => {

    const getConfigurableProps = () => ({
        showArrows: false,
        showStatus:  false,
        showIndicators:  true,
        infiniteLoop:  true,
        showThumbs:  true,
        useKeyboardArrows: true,
        autoPlay: false,
        stopOnHover: true,
        swipeable: true,
        dynamicHeight: true,
        emulateTouch: true,
        autoFocus: true,
        thumbWidth: 100,
        selectedItem: 0,
        interval: 2000,
        transitionTime: 500,
        swipeScrollTolerance:  5,
    });

    return (
        <Carousel {...getConfigurableProps()}>
            {images.map((item) => (
                <div>
                    <img src={item} />
                </div>
            ))}
        </Carousel>
    )
}

export default ImageSlider
