import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    maxWidth: '75vw',
    fontFamily: 'roboto'
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const buttonsStyle = {
  display:'flex',
  width:'100%',
  height:'auto',
  flexDirection:'row',
  justifyContent:'center',
  alignItems:'center',
  marginTop: '40px',
}


function TwoOptions({id, yes, no, title, text, handleOpenCloseModal, modalStatus, orderHandler}) {

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{title}</h2>
      <p id="simple-modal-description">{text}</p>
      <div style={buttonsStyle}>
        <Button variant="contained" style={{margin: '0 20px'}} onClick={()=>handleOpenCloseModal(false)}>{no}</Button>
        <Button variant="contained" color="secondary" style={{margin: '0 20px'}} onClick={()=>orderHandler(id)}>{yes}</Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={modalStatus}
        onClose={()=>handleOpenCloseModal(false)}
        key={id}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default TwoOptions
