import React from 'react';
import './Style/Modal.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const RedirecModal = ({msg, happening}) => {
    return (
    <div className='modal'>
        <div className='mainModal'>
            <div><CheckCircleIcon fontSize='large' style={{ color: 'green' }} /></div>
            <h4>{msg}</h4>
            <span>{happening}</span>
        </div>
    </div>
    )
}

export default RedirecModal
