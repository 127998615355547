import axios from 'axios'
import React, {useState, useEffect, useContext} from 'react'
import {Ads, CenterErrorMsg, Loading} from '../../components'
import SortAds from '../Sort/SortAds'
import {adminContext} from '../../context/adminContext'

const AdsList = ({admin}) => {

    const [adverts, setAdverts] = useState([])
    const [sort, setSort] = useState({})
    const [sortStatus, setSortStatus] = useState('')
    const [isLoading, setIsLoading] = useState(true)// just for showing NO RESULT massage
    const [error, setError] = useState('')

    const statusChanged = useContext(adminContext).statusChanged
    const FETCH_URL = useContext(adminContext).FETCH_URL

    const fetchUserAdverts = async () => {
        setIsLoading(true)
        const getData = axios.get(`${FETCH_URL}/allAds`, {params: {params: sort, status: sortStatus}})
        .then((res)=>{
            if(res.status===200) setAdverts(res.data)
            else(console.log(res.status))
        })
        .catch(err=>setError('Server Error! Please Try Again ...'))
        .then(()=>setIsLoading(false))
    }

    useEffect(()=>{
        fetchUserAdverts()
    }, [sort, sortStatus, statusChanged])

    return (
        <div>
            <SortAds setSort={setSort} setSortStatus={setSortStatus} admin={true} />
            {isLoading && <Loading />}
            {adverts.length!==0 && <Ads advertisements={adverts} admin={admin} />}
            {(!adverts.length && !isLoading && !error.length) && <CenterErrorMsg message="No Result To Show!" />}
            {(!adverts.length && !isLoading && error.length) && <CenterErrorMsg message={error} />}
        </div>
    )
}

export default AdsList
