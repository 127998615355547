import React, {useState, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import './styles.css';
import { Main, AdDetails, Register, Login, Panel, Contact, Admin, Consulting } from './pages';
import { Navbar, NotFound, SendAds, UpdateAds, Loading, Footer } from './components';
import UserContextProvider from './context/UserContext';
import AdminContextProvider from './context/adminContext';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie'

function App() {

// Get user info from login or register
  const [userInfo, setUserInfo] = useState({});

// Use translation from i18n
  const { t } = useTranslation();

// Language options
  const initialLanguageOptions = [
    {name: 'gb', countryCode: 'gb'},
    {name: 'tr', countryCode: 'tr'},
    {name: 'de', countryCode: 'de'},
    {name: 'ir', countryCode: 'ir', dir: 'rtl'},
    {name: 'ar', countryCode: 'ae', dir: 'rtl'}
  ];
  function handleLngSelect(name){
    if(name){
      i18next.changeLanguage(name)
    }
  }

  const currentLanguageCode = cookies.get('i18next');
  const currentLanguage = initialLanguageOptions.find(i=>i.countryCode === currentLanguageCode) || 'en'

  const languageOptions = { select: currentLanguage.name, handleLngSelect: handleLngSelect, options: initialLanguageOptions, name: t('language') }

  function changeFarsiFont(){
    if(currentLanguageCode === 'ir' || currentLanguageCode === 'ar') window.document.body.classList.add('persianFont');
    else window.document.body.classList.remove('persianFont')
  }

  // let location = useHistory();
  // useEffect(()=>{
  //   document.body.dir = currentLanguage.dir || 'ltr'
  // }, [currentLanguage])

  return (
    <UserContextProvider>
      {changeFarsiFont()}
      <Router>
        <div className="App">
          <div className='container' style={{maxWidth: 1350, margin: 'auto'}}>
            <Navbar languageOptions={languageOptions} />
            <Switch>
              <Route path="/" exact>
                <Main />
              </Route>
              <Route path="/Register">
                <Register setUserInfo={setUserInfo} />
              </Route>
              <Route path='/Login'>
                <Login setUserInfo={setUserInfo} />
              </Route>
              <Route path='/AdDetails/:id'>
                <AdDetails />
              </Route>
              <Route path='/Panel'>
                <Panel />
              </Route>
              <Route path='/Contact'>
                <Contact />
              </Route>
              <Route path='/SendAds'>
                <SendAds />
              </Route>
              <Route path='/UpdateAds'>
                <UpdateAds />
              </Route>
              <Route path='/consulting'>
                <Consulting />
              </Route>
              <AdminContextProvider>
                <Route path='/Admin'>
                  <Admin />
                </Route>
              </AdminContextProvider>
              <Route component={NotFound} />  {/* Not found 404 */}
            </Switch> 
            <Footer />
          </div>
        </div>
      </Router>
    </UserContextProvider>
  );
}

export default App;

