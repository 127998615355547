import React, {useContext, useEffect, useState, Suspense} from 'react';
import { UserContext } from '../context/UserContext';
import { useLocation } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import {ImageSlider, FeatureList, Ads, Loading} from '../components';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'

const AdDetails= () => {

    const context = useContext(UserContext)
    const FETCH_URL = context.FETCH_URL

    const {t} = useTranslation()
    const lang = cookies.get('i18next')

    const location = useLocation()
    const [path, setPath] = useState(location.pathname)
    const id = location.pathname.split('/')[2];

    const userEmail = context.cookies.loginData ? context.cookies.loginData.email : ''
    const favAds = context.cookies.loginData ? context.cookies.loginData.fav : ''

    const [ad, setAd] = useState('')
    const fetchAd = async () => {
        const initData = await fetch(`${FETCH_URL}/ad/?id=${id}`);
        const result = await initData.json();
        setAd(result)
        fetchAdUserDetails(result.user)
    }

    const [adUserDetails, setAdUserDetails] = useState('')
    const fetchAdUserDetails = async (email) => {
        const initData = await fetch(`${FETCH_URL}/aduserdetails/?email=${email}`);
        const result = await initData.json();
        setAdUserDetails(result)
    }

    const [fav, setFav] = useState(null)
    const [favCheck, setFavCheck] = useState(false)
    const ifAdIsFav = async () => {
        if(userEmail){
            const fetchingUser = await fetch(`${FETCH_URL}/user/?email=${userEmail}`)
            const result = await fetchingUser.json()
            if(result){
                const adExistsInFavList = result.fav.indexOf(id);
                if(adExistsInFavList>=0) setFav(true)
                setFavCheck(true)
            }
        } 
    }

    async function  handleFav(e, status){
        if(userEmail){
            if(status){
                try {
                    const addToFav = await axios.put(`${FETCH_URL}/addToFavList`, {email: userEmail, id: id})
                    .then(res=>setFav(res.data))
                    .catch(error=>console.log(error))
                } catch (error) {
                    console.log(error)
                }
            }else{
                try {
                    const removeFromFav = await axios.put(`${FETCH_URL}/removeFromFavList`, {email: userEmail, id: id})
                    .then(res=>setFav(!res.data))
                    .catch(error=>console.log(error))
                } catch (error) {
                    console.log(error)
                }
            }
        }else{
            console.log('User not found')
        }
    }

// Get similar ad in adDetails
    const [similarAds, setSimilarAds] = useState([])
    const getSimilar = async () => {
        if(ad.price || ad.size || ad.bed || ad.kindOfProperty){
            const initialData = await fetch(`${FETCH_URL}/similar/?city=${ad.city}&province=${ad.province}&kindOfProperty=${ad.kindOfProperty}&size=${ad.size}&price=${ad.price}&bed=${ad.bed}`)
            const similarAds = await initialData.json()
            let similarAdsExceptCurrentOne = similarAds.filter(item=>(item._id !== ad._id))
            setSimilarAds(similarAdsExceptCurrentOne)
        }
    }

    useEffect(()=>{
        fetchAd();
        ifAdIsFav();
    },[])

    useEffect(()=>{
        getSimilar()
    },[ad])

    useEffect(()=>{
        if(path !== location.pathname){
            window.location.reload()
            setPath(location.pathname)
        } 
    },[location.pathname])

    const imageForImageSlider = files => {
        if(files.length){
            // const images = files.map(image=>{return process.env.PUBLIC_URL+'/upload/'+image})
            const images = files.map(image=>{return `${FETCH_URL}/User/uploaded/${image}`})
            return images
        } 
        // return [process.env.PUBLIC_URL+'/upload/defaultAdsImage.jpg']
        return [`${FETCH_URL}/User/uploaded/defaultAdsImage.jpg`]
    }

    const style = {
        btnDiv: {padding: '10px', textAlign: 'right'}, favBtn: {fontSize: '30px', cursor: 'pointer'},
        detailsGridStyle : {
            padding: window.innerWidth<'600' ? '0' : '24px', 
            direction: (lang==='ir' || lang==='ar')?'rtl':'ltr'
        },
        adTitle: {padding: '0 15px', marginBottom: 20, fontFamily: 'poppine'}
    }

    return (
        // <Suspense fallback={<Loading />}>
        <React.Fragment>
        {ad==='' && <Loading />}
        {ad._id ? <Grid container spacing={6} style={{margin: '30px 0 0 0', padding: '0', width: '100%'}}>
            <Grid style={{padding: window.innerWidth<'600' ? '0' : '24px'}} item key={ad._id} xs={12} md={6}>
                <ImageSlider images={imageForImageSlider(ad.adsFiles)} />
            </Grid>
            {/* <Grid style={{padding: window.innerWidth<'600' ? '0' : '24px', direction: lang==='ir'?'rtl':'ltr'}} item key={ad.id} xs={12} md={6}> */}
            <Grid style={style.detailsGridStyle} item key={ad.id} xs={12} md={6}>
                <Typography variant='h5' style={style.adTitle}>{t(`${ad.kindOfProperty}`)} in {ad.city} - {ad.neighborhood}</Typography>
                {favCheck && <div style={style.btnDiv}>{ fav ? <FavoriteIcon style={style.favBtn} color='secondary' onClick={e=>handleFav(e, false)} /> : <FavoriteBorderIcon style={style.favBtn} onClick={e=>handleFav(e, true)} /> }</div>}
                <FeatureList 
                    address={ad.address}
                    price={ad.price}
                    size={ad.size}
                    title={ad.title}
                    bedroom={ad.bed}
                    date={ad.date}
                    floor={ad.floor}
                    description={ad.description}
                    overallFloor={ad.overallFloor}
                    overallUnit={ad.overallUnit}
                    features={ad.features}
                    adUserDetails={adUserDetails}
                />
            </Grid>
        </Grid>:''}
        {similarAds.length > 0 && 
            <div>
                <Typography variant="h5" style={{padding: '15px 15px 0 15px'}}>{t(`similar_advertisements`)}</Typography>
                <Ads advertisements={similarAds} noPagination={true} />
            </div>
        }
        </React.Fragment>
        // </Suspense>
    )
}

export default AdDetails