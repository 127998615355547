import React, {useContext, Suspense} from 'react'
import useStyles from './styles';
import {Link, useLocation} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import { useTranslation } from 'react-i18next'

const LargeScreenList = ({tabs, logo}) => {
    const styles = useStyles();

    const {t, i18n} = useTranslation()

    const user = useContext(UserContext).cookies

    const adminPathname = useLocation().pathname.slice(1, 6) || ''

    function deriveList(){
        var el = [];
        for(var tab in tabs){
            // el.push(<li className={styles.li} key={tab} onMouseOver={e=>showSubMenu(e)} onMouseLeave={hideSubMenu}>{t(`${tab}`)}
            //     <ul className={`${styles.subListLarge}`} key={tab+'-ul'} onMouseLeave={hideSubMenu}>
            //         {tabs[tab].map(item=>(
            //             <li className={styles.subListLiLarge} key={tab+'-'+item}>{t(`${item}`)}</li>
            //         ))}
            //     </ul>
            // </li>)
            el.push(<Link to={tabs[tab]}><li className={styles.li} key={tab}>{t(`${tab}`)}</li></Link>)
        }
        return el
    }

    // function showSubMenu(e){
    //     const subList = e.target.childNodes[1];
    //     if(subList && e.target.classList.contains(styles.li)){
    //         const allSubMenus = document.querySelectorAll(`ul.${styles.subListShow}`);
    //         if(allSubMenus)
    //             allSubMenus.forEach(item=>{item.classList.remove(styles.subListShow)});
    //         if(!subList.classList.contains(styles.subListShow))
    //             subList.classList.add(styles.subListShow);
    //     }
    // }

    // function hideSubMenu(){
    //     const allSubMenus = document.querySelectorAll(`ul.${styles.subListShow}`);
    //     if(allSubMenus)
    //         allSubMenus.forEach(item=>{item.classList.remove(styles.subListShow)});
    // }

    return (
        <div className={styles.ulDiv}>
            {/* <Link to='/'>
                <div className={styles.logoDiv}>
                    <img src={logo} alt='logo' />
                </div>
            </Link> */}
            <ul className={styles.ul}>
                {deriveList()}
                <Link to='/contact'><li className={styles.li} key='Contact'>{t('Contact_us')}</li></Link>
                {!user.loginData && <Link to='/login'><li className={styles.li} key='Login'>{t('Login')}</li></Link>}
                {user.loginData && <Link to='/panel'><li className={styles.li} key='Profile'>{t('Profile')}</li></Link>}
            </ul>
        </div>
    )
}

export default LargeScreenList
