import React, {useState, useContext, useEffect} from 'react'
import { Ads, Filters, CenterErrorMsg, AdsSkeleton, StarterContext} from '../components'
import { UserContext } from '../context/UserContext';
import ResizeListener from '../components/Navbar/ResizeListener';
// import { useTranslation } from 'react-i18next';

const Main = () => {
    // const {t} = useTranslation()

    let screenWidth = ResizeListener();
    let [hideBackgroundImage, setHideBackgroundImage] = useState(false);
    useEffect(()=>{
        if(screenWidth<=1300)
            setHideBackgroundImage(true);
        else
            setHideBackgroundImage(false);
    },[screenWidth]);

// Background image
    const BackgroundImage = () => (
        <div style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/mainImages/BANNER2.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            maxWidth: '1440px',
            height: '630px',
            margin: 'auto',
            position: 'relative'
        }}></div>
    )

// Main data resieve from context
    const context = useContext(UserContext);
    const FETCH_URL = context.FETCH_URL;
    const locations = context.locations;

// Page Number
    const [page, setPage] = useState(1)
    const [adsCount, setAdsCount] = useState(0)

// Fetch advertisement from DB
    const [isLoading, setIsLoading] = useState(true)
    const [getFilters, setGetFilters] = useState({})
    const [adverts, setAdverts] =useState([])

    const fetchAdverts = async () => {
        setAdverts([])
        let query = Object.keys(getFilters).map(key => key + '=' + createQuery(getFilters[key])).join('&');
        
        const totalAds = await fetch(`${FETCH_URL}/totalAds/?${query}`, { mode: 'cors' })
        const total = await totalAds.json()

        query += `&status=active&page=${page}`

        const initData = await fetch(`${FETCH_URL}/ads/?${query}`, { mode: 'cors' })
        const result = await initData.json()

        setAdsCount(total)
        setAdverts(result)
        setIsLoading(false)
    }

    const createQuery = (val) => {
        if(typeof(val) === 'string'){
            return val;
        }
        if(Array.isArray(val)){
            return val
        }
        if(typeof(val) === 'object'){
            var result = Object.keys(val).map(key => key + ':' + val[key]).join(',');
            return `{${result}}`
        }
        return val;
    }
    useEffect(()=>{
        if(Object.keys(getFilters).length){
            fetchAdverts();
        }
    }, [getFilters])

// All Locations Settings
    const [getAllLocations, setGetAllLocations] = useState(false) // to determine all the location filters are set and load filter in page
    const [initialLocationOptions, setInitialLocationOptions] = useState({})
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [neighborhoodOptions, setNeighborhoodOptions] = useState('');
    const [provinceSelected, setProvinceSelected] = useState('');
    const [citySelected, setCitySelected] = useState('');
    const [neighborhoodSelected, setNeighborhoodSelected] = useState('');

    useEffect(()=>{
        if(Object.keys(locations).length && getAllLocations === false){
            const initialProvinceOptions = Object.keys(locations)
            setProvinceOptions(initialProvinceOptions);
            setInitialLocationOptions(locations)
        }
    },[locations])
    // fill province dropdown
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(provinceOptions.length){
                if(provinceSelected==='') setProvinceSelected(provinceOptions[0])
                if(provinceSelected) setCityOptions(Object.keys(initialLocationOptions[provinceSelected]))
                else setCityOptions(Object.keys(initialLocationOptions[provinceOptions[0]]))
            }
        }
    },[provinceSelected, initialLocationOptions])
    // fill city dropdown
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(cityOptions.length){
                setCitySelected(cityOptions[0]);
                setNeighborhoodOptions(initialLocationOptions[provinceSelected][cityOptions[0]]);
            }
        }
    },[cityOptions, initialLocationOptions])
    // fill neighborhood dropdown by selecting city
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(citySelected) setNeighborhoodOptions(initialLocationOptions[provinceSelected][citySelected])
        }
    },[citySelected, initialLocationOptions])
    // fill neighborhood selected by changing neighborhood options
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(neighborhoodOptions) setNeighborhoodSelected(neighborhoodOptions[0])
            setGetAllLocations(true)
        }
    },[neighborhoodOptions,initialLocationOptions]);
// End of Locations settings
    

// All other filters initial values
    // const initialKindOfPropertyItem = { apartment: true, house: true, store: true, ['official-place']: true}
    const initialKindOfPropertyItem = ['apartment', 'house', 'store', 'official-place']
    const initialSizeValues = [0, 10000]
    const initialBedValues = [-1, 10]
    const initialPriceValues = [0, 1000000000]
    // const initialFeatures = {
    //     "Elevator":false,
    //     "Pet-friendly":false,
    //     "Furnished":false,
    //     "Parking-Space":false,
    //     "Electricity-Backup":false,
    //     "Air-Conditioned":false,
    //     "Central-Heating":false,
    //     "Double-Glazed-Windows":false,
    //     "Balcony":false,
    //     "Lobby":false,
    //     "Gym":false,
    //     "Pool":false,
    //     "Kids-Play-Area":false
    // }
    const initialFeatures = [
        "Elevator",
        "Pet-friendly",
        "Furnished",
        "Parking-Space",
        "Electricity-Backup",
        "Air-Conditioned",
        "Central-Heating",
        "Double-Glazed-Windows",
        "Balcony",
        "Lobby",
        "Gym",
        "Pool",
        "Kids-Play-Area"
    ]

// Set all other filters initial values in states
    const [types, setTypes] = useState('');
    const [kindOfPropertyItem, setKindOfPropertyItem] = useState(initialKindOfPropertyItem);
    const [sizeValues, setSizeValues] = useState(initialSizeValues)
    const [bedValues, setBedValues] = useState(initialBedValues)
    const [priceValues, setPriceValues] = useState(initialPriceValues)
    const [features, setFeatures] = useState([]);

// Reset filters to initial values (except location)
    const [reset, setReset] = useState(false);

// Return back filtered data base on filters
    useEffect(()=>{
        setIsLoading(true)
        const filtering = {type:'' ,kindOfProperty: [], features: ''}

        filtering.type = types
        // Object.keys(kindOfPropertyItem).map(p=>{
        //     if(kindOfPropertyItem[p]===true)
        //         filtering.kindOfProperty.push(p)
        // })
        filtering.kindOfProperty = kindOfPropertyItem
        // Object.keys(features).map(f=>{
        //     if(features[f]===true)
        //         filtering.features = filtering.features.concat(`features.${f}=true,`);
        // })
        features.map(f=>{filtering.features = filtering.features.concat(`features.${f}=true,`)}) 
        filtering.size = [sizeValues[0],sizeValues[1]]
        filtering.bed = Array.isArray(bedValues)?[bedValues[0],bedValues[1]]:bedValues
        filtering.price = [priceValues[0],priceValues[1]]
        if(provinceSelected!=='All') filtering.province = provinceSelected
        if(citySelected!=='All') filtering.city = citySelected
        if(neighborhoodSelected!=='All') filtering.neighborhood = neighborhoodSelected

        setGetFilters(filtering)
    },[types, kindOfPropertyItem, sizeValues, bedValues, priceValues, features, neighborhoodSelected, page])

// Reset useEffect filters
    useEffect(()=>{
        setKindOfPropertyItem(initialKindOfPropertyItem)
        setSizeValues(initialSizeValues)
        setBedValues(initialBedValues)
        setPriceValues(initialPriceValues)
        setFeatures([])

        setReset(false)
    },[reset])

// Scroll to top
    useEffect(()=>{
        window.scrollTo({top: 0, behavior: "smooth"})
    },[page])

    function countPagination(){
        if((adsCount%12)>=1) return Math.floor(adsCount/12)+1
        return Math.floor(adsCount/12)
    }

    return (
        <div>
            {(page === 1 && !hideBackgroundImage) && (
                <><BackgroundImage /> <StarterContext /></>
            )}
            {getAllLocations && <Filters 
                types={types}
                setTypes={setTypes}
                initialKindOfPropertyItem={initialKindOfPropertyItem}
                kindOfPropertyItem={kindOfPropertyItem}
                setKindOfPropertyItem={setKindOfPropertyItem}
                sizeValues={sizeValues}
                setSizeValues={setSizeValues}
                bedValues={bedValues}
                setBedValues={setBedValues}
                priceValues={priceValues}
                setPriceValues={setPriceValues}
                initialFeatures={initialFeatures}
                features={features}
                setFeatures={setFeatures}
                setReset={setReset}
                provinceSelected={provinceSelected}
                setProvinceSelected={setProvinceSelected}
                citySelected={citySelected}
                setCitySelected={setCitySelected}
                neighborhoodSelected={neighborhoodSelected}
                setNeighborhoodSelected={setNeighborhoodSelected}
                provinceOptions={provinceOptions}
                cityOptions={cityOptions}
                neighborhoodOptions={neighborhoodOptions}
            />}
            {
                (!adverts.length && isLoading) 
                ?
                <AdsSkeleton count={4} /> 
                :
                adverts.length!=0 
                ?
                <Ads advertisements={adverts} page={page} setPage={setPage} adsCount={countPagination()} isLoading={isLoading} /> 
                :
                <CenterErrorMsg message="no_result_to_show" />
            }
        </div>
    )
}

export default Main