import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { TwoOptions } from '../../index';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    rowTable:{
        transition: 'all .3s',
        boxShadow: '0px -3px 8px -6px grey',
    },
    feature: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableHead:{
        backgroundColor: 'rgb(103, 188, 255)',
        '& th': {
            fontFamily: 'poppine',
            fontSize: '15px',
        }
    },
    inlineTr: {
        backgroundColor: '#dfeaff',
    }
});

function Row({row, key, orderHandler}) {

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={`${classes.root} ${classes.rowTable}`} key={key}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.title}</TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.kindOfProperty}</TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">{row.price}</TableCell>
                <TableCell align="left">{row.city}</TableCell>
                <TableCell align="left">{row.neighborhood}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.bed}</TableCell>
                <TableCell align="left">{row.floor}</TableCell>
                <TableCell align="left">{row.size}</TableCell>
                <TableCell align="left"><EditIcon style={{color: '#1976d2', marginRight: '8px', cursor: 'pointer'}} /><DeleteForeverIcon style={{color: 'red', cursor: 'pointer'}} onClick={()=>setOpenModal(true)} /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={14}>
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Details
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow className={classes.inlineTr}>
                                    <TableCell colSpan={3}>Features</TableCell>
                                    <TableCell colSpan={3}>Address</TableCell>
                                    <TableCell colSpan={6}>description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={row.date}>
                                    <TableCell component="th" scope="row" colSpan={3}>
                                        {/* {Object.keys(row.features[0]).map(k=>(
                                            <div className={classes.feature}>{k} {row.features[0][k]?<CheckIcon style={{ color: green[500] }} />:<CloseIcon color="secondary"/>}</div>
                                        ))} */}
                                    </TableCell>
                                    <TableCell colSpan={3}>{row.address}</TableCell>
                                    <TableCell colSpan={3}>{row.description}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TwoOptions title='Be Careful!' text={`Are you sure to delete item with ID: ${row.id}`} id={row.id} yes='Delete' no='Cancel' modalStatus={openModal} handleOpenCloseModal={setOpenModal} orderHandler={orderHandler} />
        </React.Fragment>
    );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function Tables({advertisements}) {

    const classes = useRowStyles();
    const [removeItem, setRemoveItem] = useState('')

    function orderHandler(id){
        console.log(`id ${id} will be removed!`)
    }

    useEffect(()=>{
        if(removeItem){
            orderHandler(removeItem);
            setRemoveItem('');
        }
    },[removeItem])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead className={classes.tableHead} style={{backgroundColor: '#67bcff'}}>
                    <TableRow>
                        <TableCell />
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">ID</TableCell>
                        <TableCell align="left">KindOfProperty</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="left">City</TableCell>
                        <TableCell align="left">Neighborhood</TableCell>
                        <TableCell align="left">phone</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Bed</TableCell>
                        <TableCell align="left">Floor</TableCell>
                        <TableCell align="left">Size</TableCell>
                        <TableCell align="left">Update</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {advertisements.map((ads) => (
                        <Row key={ads.id} row={ads} orderHandler={setRemoveItem} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default Tables

