import axios from 'axios';
import React, {useContext, useState, useEffect} from 'react';
import { Redirect } from 'react-router';
import { adminContext } from '../../context/adminContext';
import RedirecModal from '../../templates/Modals/RedirecModal';
import '../Users/userStyles.css'

const MyProfile = ({adminData, logout}) => {
    
// Get user Cookie from context
    const context = useContext(adminContext)
    const updateAdminData = context.updateAdminData

// Set initial states
    const [newAdminData, setNewAdminData] = useState({})
    const [errorText, setErrorText] = useState('');
    const [adminUpdated, setAdminUpdated] = useState(false);

// Collect password filed
    const newPassOnChange = (e) => {
        setNewAdminData(prev=>({...prev, [e.target.id]: e.target.value}))
    }

// Checking form for errors
    const checkForm = (e) => {
        setErrorText('')
        if(Object.keys(newAdminData).length>=3){
            let error = false;
            Object.keys(newAdminData).map(f=>{
                if(newAdminData[f]===''){
                    if(!error) setErrorText(`Please complete ${f} filed`)
                    error = true;
                }
            })
            if(error) return false;
            else return true;
        }else{
            setErrorText('Please complete all password fields');
            return false
        }
    }
    const checkLogicForm = (e) => {
        if(adminData && newAdminData){
            if(adminData.password!==newAdminData.currentPassword){
                setErrorText('Your current password is wrong')
                return false;
            }
            if(newAdminData.newPassword.length<=5 || newAdminData.confirmPassword.length<=5){
                setErrorText('Your new password must be at least 6 character')
                return false;
            }
            if(newAdminData.newPassword!==newAdminData.confirmPassword){
                setErrorText('your new passwords are not matched!')
                return false;
            }
            return true;
        }
    }

// Updating user data
    const updateUserData = async(e) => {
        e.preventDefault()
        const validCheckForm = checkForm(e);
        if(validCheckForm){
            const validCheckLogicForm = checkLogicForm(e);
            if(validCheckLogicForm){
                // console.log(newAdminData)
                const params = {email:adminData.email, password:newAdminData.newPassword}
                const updatingAdmin = await updateAdminData(params)
                if(updatingAdmin === 200) setAdminUpdated(true)
                else console.log(updatingAdmin)
            }
        }
    }

// Refresh page after updating
    useEffect(()=>{
        if(adminUpdated){
            const refreshing = setInterval(()=>{
                logout();
                // window.location.reload();
                <Redirect to='/admin' />
                clearInterval(refreshing)
            },2000)
        }

    },[adminUpdated])
    
    return (
        <React.Fragment>
            {adminData &&
            <div className='mainDiv'>
                {adminUpdated && <RedirecModal msg='Your password has updated successfully.' happening={'Please wait...'} />}
                <div className='fieldDiv'>
                    <form method='POST' onSubmit={e=>updateUserData(e)} key='myProfileForm' >
                        <label for='username'>username</label>
                        <input id='username' type='text' value={adminData.username} disabled />
                        <label for='email'>email</label>
                        <input id='email' type='text' value={adminData.email} disabled />
                        <div className='changePassDiv'>
                            <label for='curPass'>change password</label>
                            <input id='currentPassword' className='passInput' type='text' placeholder='current password' onChange={e=>newPassOnChange(e)} />
                            <input id='newPassword' className='passInput' type='text' placeholder='new password' onChange={e=>newPassOnChange(e)} />
                            <input id='confirmPassword' className='passInput' type='text' placeholder='confirm password' onChange={e=>newPassOnChange(e)} />
                        </div>
                        <div id='passError' className='passError'>{errorText}</div>
                        <div className='btnDiv'>
                            <button className='submitBtn' type='submit'>Save Changes</button>
                        </div>
                    </form>
                </div>
            </div>
            // : <Redirect to='/admin/login' />
            }
        </React.Fragment>
    )
}

export default MyProfile
