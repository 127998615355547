import React, { useState } from 'react';
import { Card, Typography, CardContent, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { green, blue, orange } from '@material-ui/core/colors';
import Feature from './Feature/Feature'
import { useTranslation } from 'react-i18next';

const FeatureList = ({ address, price, size, date, floor, bedroom, description, overallFloor, overallUnit, features, adUserDetails }) => {

    const {t} = useTranslation()

    const styles = useStyles()
    const [showContactDetails, setShowContactDetails] = useState(false);
    const [showAllFeatures, setShowAllFeatures] = useState(false);

    const showDate = `${new Date(date).getFullYear()}/${new Date(date).getMonth()}/${new Date(date).getDate()} - ${new Date(date).getHours()}:${new Date(date).getMinutes()}`

    const contactDetailsHandler  = () => {
        setShowContactDetails(prev=> !prev);
    }
    const AllFeaturesHandler  = () => {
        setShowAllFeatures(prev=> !prev);
    }

    let open = !showContactDetails ? 'show' : 'hide';
    let featureOpen = !showAllFeatures ? 'show' : 'hide';

    return (
        <Card className={styles.mainProductDiv}>
            <CardContent>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`price`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{price>1?t('TL')+new Intl.NumberFormat({style: 'currency', currency: 'TRY'}).format(price):t('agreement')}</Typography>
                </div>	
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`size`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{size?size:'-'} &#13217;</Typography>
                </div>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`address`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{address?address:'-'}</Typography>
                </div>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`bedroom`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{bedroom?bedroom:'-'}</Typography>
                </div>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`floor`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{floor?floor:'-'}</Typography>
                </div>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`all_floors`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{overallFloor?overallFloor:'-'}</Typography>
                </div>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`all_units`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{overallUnit?overallUnit:'-'}</Typography>
                </div>
                <Button variant="contained" size="large" color="primary" className={styles.featureBtn} onClick={()=>AllFeaturesHandler()}>{t(`${featureOpen}`)} {t(`all_features`)}</Button>
                {showAllFeatures && 
                    <div className={styles.featuresDiv}>
                        <Grid container spacing={3}>
                            {Object.keys(features).length ? Object.keys(features).map(f=><Feature feature={f} status={features[f]} />) : t('no_feature_listed')}
                        </Grid>
                    </div>
                }
                <Divider variant="middle" style={{margin: '15px 0'}} />
                <div className={styles.cardContent}>
                    <p style={{fontFamily: 'roboto', fontSize: 17, whiteSpace: 'pre-line'}}>{description}</p>
                </div>
                <Divider variant="middle" style={{margin: '15px 0'}} />
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom>{t(`published_date`)}:</Typography>
                    <Typography varient='h5' className={styles.details}>{showDate}</Typography>
                </div>
                <Button variant="contained" size="large" color="primary" className={styles.contactBtn} onClick={()=>contactDetailsHandler()}>{t(`${open}`)} {t(`contact_details`)}</Button>
                {(showContactDetails && adUserDetails) &&
                    <div className={styles.contactDetails}>
                        <div className={styles.contactItem}><PhoneIcon style={{ color: blue[500], fontSize: 40, marginRight: '10px' }} />  {adUserDetails.mobile}</div>
                        <div className={styles.contactItem}><WhatsAppIcon style={{ color: green[500], fontSize: 40, marginRight: '10px' }} />  {adUserDetails.mobile}</div>
                        <div className={styles.contactItem}><MailOutlineIcon style={{ color: orange[500], fontSize: 40, marginRight: '10px' }} />  {adUserDetails.email}</div>
                    </div>
                }
            </CardContent>
        </Card>
    )
}

export default FeatureList
