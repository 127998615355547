import React, {useState, useEffect} from 'react'
import { Grid, makeStyles } from '@material-ui/core';
// import Checkbox from '@material-ui/core/Checkbox';
import {DropDown} from '../../../components'
import { useTranslation } from 'react-i18next';

const ShowLocations = ({locations, provinceSelected, setProvinceSelected,citySelected, setCitySelected,neighborhoodSelected, setNeighborhoodSelected, currentAdLocation}) => {
    
    const {t} = useTranslation()
    const location = Object.assign({}, locations)
    delete location.All

// to set current ad location first if it exists
    const [initialAdLocation, setInitialAdLocation] = useState(false)

// All Locations Settings
    const [getAllLocations, setGetAllLocations] = useState(false) // to determine all the location filters are set and load filter in page
    const [initialLocationOptions, setInitialLocationOptions] = useState({})
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [neighborhoodOptions, setNeighborhoodOptions] = useState('');

    useEffect(()=>{
        if(Object.keys(location).length && getAllLocations === false){
            const initialProvinceOptions = Object.keys(location)
            setProvinceOptions(initialProvinceOptions);
            setInitialLocationOptions(location)
        }
    },[location])
// fill province dropdown
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(provinceOptions.length){
                if(provinceSelected==='' && !initialAdLocation && currentAdLocation) setProvinceSelected(currentAdLocation.province) // new one
                if(provinceSelected==='' && !currentAdLocation) setProvinceSelected(provinceOptions[0])
                if(provinceSelected) setCityOptions(Object.keys(initialLocationOptions[provinceSelected]))
                else setCityOptions(Object.keys(initialLocationOptions[provinceOptions[0]]))
            }
        }
    },[provinceSelected, initialLocationOptions])
// fill city dropdown
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(cityOptions.length){
                if(!initialAdLocation && currentAdLocation){// new one
                    setCitySelected(currentAdLocation.city);// new one
                    setNeighborhoodOptions(initialLocationOptions[provinceSelected][currentAdLocation.city]);   // new one  
                } // new one
                else{
                    setCitySelected(cityOptions[0]);
                    setNeighborhoodOptions(initialLocationOptions[provinceSelected][cityOptions[0]]);
                }
            }
        }
    },[cityOptions, initialLocationOptions])
// fill neighborhood dropdown by selecting city
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(citySelected) setNeighborhoodOptions(initialLocationOptions[provinceSelected][citySelected])
        }
    },[citySelected, initialLocationOptions])
// fill neighborhood selected by changing neighborhood options
    useEffect(()=>{
        if(Object.keys(initialLocationOptions).length) {
            if(neighborhoodOptions && !initialAdLocation && currentAdLocation) {
                setNeighborhoodSelected(currentAdLocation.neighborhood) // new one
                setInitialAdLocation(true) // new one
            }
            if(neighborhoodOptions && currentAdLocation && initialAdLocation) {setNeighborhoodSelected(neighborhoodOptions[0])}
            if(neighborhoodOptions && !currentAdLocation) {setNeighborhoodSelected(neighborhoodOptions[0])}
            setGetAllLocations(true)
        }
    },[neighborhoodOptions,initialLocationOptions]);
// End of Locations settings

    return (
        <React.Fragment>
            {getAllLocations && 
            <Grid container spacing={3}>
                <Grid item key='province' xs={12}>
                    {provinceOptions && <DropDown name='province' options={provinceOptions} select={provinceSelected} handleSelect={setProvinceSelected} width='100%' />}
                </Grid>
                <Grid item key='province' xs={12}>
                    {cityOptions && <DropDown name='city' options={cityOptions} select={citySelected} handleSelect={setCitySelected}  width='100%' />}
                </Grid>
                <Grid item key='neighborhood' xs={12}>
                    {neighborhoodOptions && <DropDown name='neighborhood' options={neighborhoodOptions} select={neighborhoodSelected} handleSelect={setNeighborhoodSelected}  width='100%' />}
                </Grid>
            </Grid>
            }
        </React.Fragment>
    )
}

export default ShowLocations
