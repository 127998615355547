import React, {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import {Ads} from '../../components'
import axios, { Axios } from 'axios';
import {Loading, CenterErrorMsg} from '../../components'

const MyFavorites = () => {

    const [isLoading, setIsLoading] = useState(true)

    const userEmail = useContext(UserContext).cookies.loginData.email || ''
    const FETCH_URL = useContext(UserContext).FETCH_URL

    const [userFavAds, setUserFavAds] = useState([])
    const fetchUserFav = async () => {
        const fetchingUser = await fetch(`${FETCH_URL}/user/?email=${userEmail}`)
        const result = await fetchingUser.json()
        if(result.fav.length) fetchUserFavAds(result.fav)
        else setIsLoading(false)
    }
    
    const fetchUserFavAds = async (data) => {
        const initData = await fetch(`${FETCH_URL}/userFavAds/?list=${data}`);
        const result = await initData.json();
        setUserFavAds(result)
        setIsLoading(false)
    }

    useEffect(()=>{
        if(userEmail.length){
            fetchUserFav()
        }
    }, [])

    async function deleleFavItem(id){
        if(id && userEmail){
            try {
                const removeFromFav = await axios.put(`${FETCH_URL}/removeFromFavList`, {email: userEmail, id: id})
                .then(res=>fetchUserFav())
                .catch(error=>console.log(error))
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <div>
            {isLoading && <Loading />}
            {userFavAds.length!==0 && <Ads advertisements={userFavAds} userFav={true} deleleFavItem={deleleFavItem} />}
            {(userFavAds.length===0 && !isLoading) && <CenterErrorMsg message='No favorite advertisement' />}
        </div>
    )
}

export default MyFavorites

