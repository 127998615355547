import React from 'react'
import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

const AdsSkeleton = ({count}) => {

// Create skeleton loading for ads
    function adLoading(count){
        const stacks = [];
        for(let i = 0; i < count; i++){
            stacks.push(    
                <Grid item key={i} xs={12} md={4} sm={6} lg={3} style={{padding:13}} spacing={2}>
                    <Skeleton variant="rectangular" width='100%' height={200} />
                    <div style={{margin: '20px 0'}}>
                        <Skeleton variant="text" width='50%' height={20} />
                        <Skeleton variant="text" width='70%' height={20} />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Skeleton variant="circle" width={40} height={40} />
                        <Skeleton variant="circle" width={40} height={40} />
                        <Skeleton variant="circle" width={40} height={40} />
                    </div>
                </Grid>
            )
        }
        return stacks
    }

    return (
        <Grid container spacing={4} style={{margin: '10px 0 50px 0',width: '100%',minHeight: '500px',display: 'flex',justifyContent: 'flex-start',}} >
            {adLoading(count)}
        </Grid>
    )
}

export default AdsSkeleton