import React, {useEffect} from 'react'
import './styles.css';
import RedirecModal from '../../templates/Modals/RedirecModal';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'

const FiledsTemplate = ({fields, name, checkForm, onChange, status, userMessage, btnText}) => {

    const {t} = useTranslation()
    const lang = cookies.get('i18next') || ''

    function errorCreator(status){
        const alerts = document.querySelectorAll('.visible');
        if(alerts.length){
            alerts.forEach(el=>{
                el.classList.remove("visible"); 
            }) 
        }
        const alertMsg = document.createElement("div");
        alertMsg.appendChild(document.createTextNode(t(`complete_this_field`)));
        if(status){
            status.map(err=>{
                document.querySelector(`input[name='${err}']`).nextSibling.classList.add("visible");
            })
        }
    }

    // useEffect(() => {
    //     if(savedUsername)
    //         document.querySelector('input[name="Username"]').value = savedUsername;
    // }, [savedUsername])

    return (
        <div className='mainDiv'>
            {userMessage.length && <RedirecModal msg='Your message sent successfully, We answer soon.' happening={'Redirecting to Main page ...'} />}
            <Typography variant='h4' className='pageTitle'>{t(`${name}`)}</Typography>
            <form key='form' onSubmit={e=>checkForm(e)} style={{direction: (lang==='ir' || lang==='ar')?'rtl':'ltr'}}>
                <div className="container">
                    { fields.length &&
                    fields.map(field=>{
                        let val = field.val? field.val: undefined; 
                        return(
                            <div className='fieldDiv' key={field.id}>
                                <label htmlFor={field.fieldName} key={field.fieldName} >{t(`${field.fieldName}`)}</label>
                                <input className={field.fieldName==='Message'?'textArea':''} minLength={field.length} type={field.type} name={field.fieldName} placeholder={t(`${field.fieldPlaceholder}`)} key={field.id} onChange={onChange} required={field.required} value={val}/>
                                <div className='alert'>{t(`complete_this_field`)}</div>
                            </div>
                        )
                    })
                    }
                    {errorCreator(status)}
                    <div className='btnDiv'>
                        <button className='submitBtn' type='submit'>{t(`${btnText}`)}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FiledsTemplate
