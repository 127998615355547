import React from 'react'

const NotFound = () => {
    return (
        <div>
            <h1 style={{textAlign: 'center', margin: '50px 0 0 0', fontSize: '100px'}}>404</h1>
            <h1 style={{textAlign: 'center', marginTop: '0px'}}>Page Not Found!</h1>
        </div>
    )
}

export default NotFound
