import React, {useState, useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const DropDown = ({options, select, handleSelect, name, width, disable=false, sort=false}) => {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <div>
            {(sort && options) && (<FormControl className={classes.formControl} style={{width: width}} disabled={disable}>
                <InputLabel id="demo-simple-select-label">{t(`${name}`)}</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={(e)=>handleSelect(e.target.value)}>
                    {options.map(item=>(
                        <MenuItem value={item} key={item}>{t(`${item}`)}</MenuItem>
                    ))}
                </Select>
            </FormControl>)}
            {(!sort && options) && (<FormControl className={classes.formControl} style={{width: width}} disabled={disable}>
                <InputLabel id="demo-simple-select-label">{t(`${name}`)}</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={select} onChange={(e)=>handleSelect(e.target.value)}>
                    {options.map(item=>(
                        <MenuItem value={item} key={item}>{t(`${item}`)}</MenuItem>
                    ))}
                </Select>
            </FormControl>)}
        </div>
    )
}

export default DropDown
