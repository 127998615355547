import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';

export default makeStyles((theme)=>({
    gridContainer:{
        margin: '15px 0', 
        minHeight: 150
    },
    fileUploadTitle:{
        margin: '20px 0 5px 0',
        fontFamily: 'Roboto',
        width: '100%',
        color: '#0000008a',
        fontSize: '1rem',
    },
    gridItem:{
        padding: 5,
        height: 125,
        maxHeight: 125,
        display: 'flex',
        position: 'relative',
        '& img':{
            width: '100%',
            height:'auto',
            margin: 'auto',
            maxHeight: '100%'
        },
    },
    removeFileDiv:{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '100%',
    },
    removeBtn:{
        color: '#ff5858',
        cursor: 'pointer',
        bottom: 0,
        margin: 'auto',
        position: 'absolute',
        width: '100%',
        height: '30%',
        left: 0,
        bottom: 0,
        transition: '0.5s',
        '& :hover':{
            color: '#ff0c0c'
        }
    },
    inputDiv:{
        position: 'relative', 
        display: 'flex', 
        width: '100%', 
        height: '100%', 
        boxShadow: '0px 0px 4px 0px grey'
    },
    input:{
        position: 'absolute', 
        opacity:0, 
        left:0, 
        top: 0,
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
    },
    p:{
        margin: 'auto', 
        color: 'rgba(0, 0, 0, 0.54)', 
        fontFamily: 'Roboto'
    }
}))