import React, {useContext, useEffect, useState} from 'react'
import {Switch, Route, useRouteMatch, NavLink, Link, useHistory} from 'react-router-dom';
import AdsList from '../components/Admin/AdsList'
import MyProfile from '../components/Admin/MyProfile'
import {NotFound} from '../components'
import LoginForm from '../components/Admin/LoginForm';
// import AdminContextProvider from '../context/adminContext';
import { adminContext } from '../context/adminContext'

const Admin = () => {
    const match = useRouteMatch()
    const history = useHistory()

// context data
    const context = useContext(adminContext)
    const FETCH_URL = context.FETCH_URL
    const adminData = context.cookies.adminData ? context.cookies.adminData : null
    const adminEmail = adminData ? adminData.email : null
    const removeAdminData = context.removeCookieData

    useEffect(()=>{
        if(history.location.pathname === '/admin' && adminData){
            history.push('/admin/AdsList')}
    })

// logout function
    const logout = () => { if(adminData) removeAdminData('adminData') }

    return (
        <div className='container' style={{marginBottom: '150px'}}>
            {adminEmail 
            ?
                <div className='adminMainDiv'>
                    <div className='adminBottomMenu'>
                        <NavLink activeClassName='activeLink' to={`${match.url}/AdsList`}>All ads</NavLink>
                        <NavLink activeClassName='activeLink' to={`${match.url}/myprofile`}>My Profile</NavLink>
                        <Link onClick={()=>logout()}>Logout</Link>
                    </div>
                    <Switch>
                        <Route exact={true} path={`${match.path}/myprofile`}>
                            <MyProfile adminData={adminData} logout={logout} />
                        </Route>
                        <Route exact={true} path={`${match.path}/AdsList`}>
                            <AdsList admin={adminData?true:false} />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </div>
            :
                <LoginForm />
            }
        </div>
    )
}

export default Admin
