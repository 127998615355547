import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
    formControl: {
        margin: '0 8px 0 0',
        minWidth: 100,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))