import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
const black = '#3a3a3a';

export default makeStyles((theme)=>({
    navbarRoot:{
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffffeb',
        zIndex: 100,
        backdropFilter: 'saturate(180%) blur(25px)',
        width: '100%',
        '@media (max-width:1125px)':{
            backgroundColor: 'white',
        }
    },
    navbarMain:{
        maxWidth: '96%',
        display: 'inline-flex',
        width: '96%',
        // height: 'auto',
        maxHeight: '86px',
        padding: '0 2%',
        // boxShadow: "1px 1px 10px grey",
    },
    logoAndList: {
        padding: '12px 0',
        flexGrow: 3,
        flexWrap: 'wrap',
        fontFamily: 'poppine',
        justifyContent: 'flex-start',
    },
    ulDiv: {
        margin: 'auto',
        display: 'inline-flex',
        width: '100%',
        '& div': {
            alignSelf: 'center',
            cursor: 'pointer',
        },
        '& ul':{
            justifyContent: 'flex-start'
        }
    },
    ul: {
        listStyle: 'none',
        display: 'inline-flex',
        alignSelf: 'center',
        padding: 0,
        maxHeight: '18px',
        width: '100%',
        justifyContent: 'center',
    },
    li: {
        height: 'auto', 
        margin: 'auto 0',
        minWidth: '150px',
        maxWidth: '150px',
        width: 'auto',
        // padding: '0 30px 0 0',
        alignSelf: 'center',
        cursor: 'pointer',
        transition: 'all .3s',
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        color: black,
        position: 'relative',
        zIndex: 1,
        '&:hover': {
            fontWeight: 'bold',
            color: '#ff8721',
        },
    },
    subListLarge: {
        margin: '25px auto 0 auto !important',
        display: 'none',
        transition: 'all .5s',
        width: '150px',
        maxWidth: '150px',
        boxShadow: '0px 6px 6px 0px grey',
        padding: '0',
        listStyle: 'none',
        backgroundColor: 'white',
        textAlign: 'left',
        '& li': {
            padding: '10px 20px',
            color: black,
            fontSize: '13px',
            '&:hover': {
                color: black,
                backgroundImage: 'linear-gradient( 271deg, #5a5a5a52, transparent)',
            },
        },
    },
    sendAds: {
        alignSelf: 'center',
        display: 'inline-flex',
        width: '250px',
        justifyContent: 'flex-end',
    },
    SendButton: {
        margin: theme.spacing(1),
        backgroundColor: '#1976d2 !important',
        minWidth: '137px !important',
        padding: '10px 0 !important',   
        '& span':{
            color: 'white',
            fontFamily: 'poppine',
            fontSize: '15px'
        }
    },

    //mobile screen list
    ulDivMobile: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    sideList: {
        width: '100%',
        height: '100vh',
        position: 'absolute',
        left: '-100vw',
        top: 61,
        transition: 'all .5s',
        backgroundImage: 'linear-gradient(180deg, #e8e8e88c, transparent)',
        zIndex: 2,
        backgroundColor: 'white',
        '& ul': {
            listStyle: 'none',
            marginTop: '40px',
            paddingLeft: '30px',
            '& svg': {
                float: 'right',
                margin: '0 10px',
            },
            '& li': {
                fontFamily: 'poppine',
                height: 'auto',
                minHeight: '50px',
                width: '100%',
                fontSize: '16px',
                transition: 'all .3s',
                color: black,
                cursor: 'pointer',
                fontWeight: 'bold',
                '&:hover': {
                    fontWeight: 'bold',
                    fontSize: '17px',
                    color: 'black',
                },
            },
        },
    },
    subList: {
        margin: '20px 0 !important',
        display: 'none',
        transition: 'all .7s',
        '& li': {
            marginBottom: '10px',
        },
    },
    arrowDiv: {
        display: 'contents',
    },
    subListShow: {
        display: 'block',
    },
    show: {
        left: 0,
    },
    hide: {
        left: '-100vw',
    },
    // End of mobile screen
    
}))
