import React from 'react'
import { useTranslation } from 'react-i18next';

const Consulting = () => {
    const {t} = useTranslation()
    return (
        <div style={{margin: '100px auto', textAlign: 'center', fontSize: 40, fontFamily: 'poppine'}}>
            {t('We_are_here_to_help_you')}
        </div>
    )
}

export default Consulting
