import React from 'react'
import { useTranslation } from 'react-i18next'

const CenterErrorMsg = ({message}) => {
    const {t} = useTranslation()
    return (
        <div style={{margin: '100px auto', fontSize: '40px', textAlign: 'center', fontFamily: 'poppine', color: '#3a3a3a'}}>
            {t(`${message}`)}
        </div>
    )
}

export default CenterErrorMsg
