import React, {useEffect} from 'react'
import { Link, useLocation } from "react-router-dom";
import './fields.css';
import RedirecModal from '../Modals/RedirecModal';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'

const FiledsTemplate = ({fields, name, checkForm, onChange, status, logicStatus, isRegistered, isLogin, question, invalidEntries, userExists, isAdminLogin}) => {

    const location = useLocation();

    const lang = cookies.get('i18next') || ''

    const {t} = useTranslation()

    function errorCreator(status){
        const alerts = document.querySelectorAll('.visible');
        if(alerts.length){
            alerts.forEach(el=>{
                el.classList.remove("visible"); 
            }) 
        }
        // const alertMsg = document.createElement("div");
        // alertMsg.appendChild(document.createTextNode("Complete this field"));
        if(status){
            status.map(err=>{
                document.querySelector(`input[name='${err}']`).nextSibling.classList.add("visible")
            })
        }
    }

    function logicErrorCreator(logicStatus){
        const alerts = document.querySelectorAll('.visible');
        if(alerts.length){
            alerts.forEach(el=>{
                if(el.classList.contains('logicAlert'))
                    el.classList.remove("visible"); 
            }) 
        }
        // const alertMsg = document.createElement("div");
        // alertMsg.appendChild(document.createTextNode("logic error!"));
        if(logicStatus){
            logicStatus.map(err=>{
                document.querySelector(`input[name='${err}']`).nextSibling.nextSibling.classList.add("visible");
                document.querySelector(`input[name='${err}']`).nextSibling.nextSibling.innerHTML=t(`${logicErrorText(err)}`)
            })
        }
    }

    function logicErrorText(err){
        switch(err){
            case 'Username':
                return 'username_must_be_at_least_6_characters';
            case 'Confirm password':
                return 'passwords_are_not_matched'
            case 'Password':
                return 'password_must_be_at_least_7_characters'
            case 'Email':
                return 'email_is_not_correct'
            case 'Mobile':
                return 'phone_is_not_correct'
            case 'Robot test':
                return 'answer_is_not_correct'
            default:
                return 'entry_is_wrong'
        }
    }

    useEffect(()=>{
        errorCreator(status)
        logicErrorCreator(logicStatus)
    },[status, logicStatus])

    return (
        <div className='mainDiv'>
            {isRegistered && <RedirecModal msg={t('you_are_registered_successfully_please_check_your_email')} happening={t('redirecting')} />}
            {isLogin && <RedirecModal msg={t('login_successfully_please_wait')} happening={t('redirecting')} />}
            {isAdminLogin && <RedirecModal msg={t('login_successfully_Welcome_please_wait')} happening={t('redirecting')} />}
            {location.pathname==='/login/send' && <div className='loginAlert'>{t("to_send_Ads_please_login")}</div>}
            <form method='POST' key='form' onSubmit={e=>checkForm(e)} style={{direction: (lang==='ir' || lang==='ar')?'rtl':'ltr'}}>
                <div className="container">
                    { fields.length &&
                    fields.map(field=>{
                        let val = field.val? field.val: undefined; 
                        return(
                            <div className='fieldDiv' key={field.id}>
                                {(field.fieldName!=='Robot test') ? <label htmlFor={field.fieldName} key={field.fieldName} >{t(`${field.fieldName}`)}</label> : <label htmlFor={field.fieldName} key={field.fieldName} >{t(`${field.fieldName}`)+question}</label>}
                                <input minLength={field.length} type={field.type} name={field.fieldName} placeholder={t(`${field.fieldPlaceholder}`)} key={field.id} onChange={onChange} required={field.required} value={val}/>
                                <div className='alerting'>{t("complete_this_field")}</div>
                                <div className='LogicAlert'>Logic Error!</div>
                            </div>
                        )
                    })
                    }
                    {/* {status.length ? t(`${errorCreator(status)}`) : ''}
                    {logicStatus.length ? t(`${logicErrorCreator(logicStatus)}`) : ''} */}
                    <div className='btnDiv'>
                        {typeof(invalidEntries)!=='undefined' && invalidEntries.user && <div className='userNotFound'>{t('user_not_found_Please_check_your_entries')}</div>}
                        {typeof(invalidEntries)!=='undefined' && invalidEntries.pass && <div className='userNotFound'>{t('user_and_Password_are_not_matched_Please_check_your_entries')}</div>}
                        {typeof(userExists)!=='undefined' && userExists.user && <div className='userNotFound'>{t('this_username_is_already_exists_please_try_another_one')}</div>}
                        {typeof(userExists)!=='undefined' && userExists.email && <div className='userNotFound'>{t('this_email_is_already_exists_please_try_another_one')}</div>}
                        {typeof(userExists)!=='undefined' && userExists.mobile && <div className='userNotFound'>{t('this_mobile_number_is_already_exists_please_try_another_one')}</div>}
                        <button className='submitBtn' type='submit'>{t(`${name}`)}</button>
                        {name==='Login' && <a className='loginOrReg' href="/Register">{t('you_do_not_have_an_account_Register_here')}</a>}
                        {name==='Register' && <Link className='loginOrReg' to="/Login">{t('you_have_an_account_Login_here')}</Link>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FiledsTemplate
