import React from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'

const StarterContext = () => {
    const {t} = useTranslation()
    const lng = cookies.get('i18next')
    const direction = (lng=='ir' || lng=='ar') ? 'rtl' : 'ltr' 
    return (
        <div className='StarterContextRoot'>
            <h4 class="wordCarousel" style={{direction}}>
                <h1>{t("we_are_here")}</h1>
                <div>
                    <ul className="flip4">
                        <li>English</li>
                        <li>Turkey</li>
                        <li>پارسی</li>
                        <li>العربیه</li>
                        <li>Deutsch</li>
                    </ul>
                </div>
            </h4>
            <p>{t("to_connect_clients_to_owners_and_constructors_directly")} <br/>{t("just_select_your_favorite_property")}</p>
        </div>
    )
}

export default StarterContext
