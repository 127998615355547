import React, {useContext, useEffect, Suspense} from 'react'
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, NavLink, useHistory } from "react-router-dom";
import { MyFavorites, MyAds, MyProfile, NotFound} from '../components';
import { UserContext } from '../context/UserContext';
import '../styles.css'

const Panel = () => {

    const {t} = useTranslation()

    const {ads} = useContext(UserContext);
    const history = useHistory()
    let match = useRouteMatch();
    const user = useContext(UserContext).cookies

    useEffect(()=>{
        if(history.location.pathname === '/panel'){
            history.push('/panel/myads')}
    })

    return (
        <Suspense fallback="">
        <React.Fragment>
        {user.loginData ? 
        <div className='mainProfileDiv'>
            <div className='profileListDiv'>
                <NavLink activeClassName='activeLink' to={`${match.url}/myads`}>{t(`my_ads`)}</NavLink>
                <NavLink activeClassName='activeLink' to={`${match.url}/myfavorites`}>{t(`my_Favorites`)}</NavLink>
                <NavLink activeClassName='activeLink' to={`${match.url}/myprofile`}>{t(`my_Profile`)}</NavLink>
            </div>
            <Switch>
                <Route exact={true} path={`${match.path}/myprofile`}>
                    <MyProfile />
                </Route>
                <Route exact={true} path={`${match.path}/myads`}>
                    <MyAds />
                </Route>
                <Route exact={true} path={`${match.path}/myfavorites`}>
                    <MyFavorites />
                </Route>
                <Route component={NotFound} />
            </Switch>
        </div>
        : <Redirect to='/login' />}
        </React.Fragment>
        </Suspense>

    )
}

export default Panel
