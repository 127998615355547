import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme)=>({
    AccordionRoot: {
        width: '100%',
        marginTop: '15px',
        '& div.MuiAccordionDetails-root':{
            '@media (max-width:780px)':{
                padding: 0
            }
        },
    },
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        '& header': {
            backgroundColor: '#dbe1ff',
            boxShadow: 'none',
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        margin: 'auto',
        fontSize: '25px',
        fontFamily: 'poppine',
        color: '#1976d2',
    },
    tabs: {
        justifyContent: 'center',
        '& div.MuiTabs-flexContainer':{
            // justifyContent: 'center',
        }
    },
    accordion: {
        boxShadow: 'none !important',
        // boxShadow: '0px -8px 8px -5px grey',
    },
    tabpanel:{
        minHeight: '120px'
    },
    sliderRoot: {
        // margin: '15px 0 30px 0',
        width: '97%',
        '& .MuiSlider-thumb':{
            width: '20px',
            height: '20px',
            borderRadius: '25%',
            bottom: '4px',
            // top: '16px',
        },
        '& .MuiSlider-valueLabel':{
            top: 32,
            left: -7,
            '& span':{
                minWidth: 'auto',
                minHeight: '20px',
                transform: 'none',
                borderRadius: '0',
                color: 'transparent',
                '& span':{
                    transform: 'none',
                    color: 'black',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    width: 'fit-content',
                }
            },
        },
    },
    feature:{
        '& div.control':{
            minHeight: 48
        }
    }
}))