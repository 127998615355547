import React, { useState, useEffect } from 'react'
import {Form} from '../components'

const Contact = () => {

    const fields = [
        {id: 1, fieldName: 'Fullname', fieldPlaceholder: 'enter_your_fullname_please',type: 'text', required: '' },
        {id: 2, fieldName: 'Email', fieldPlaceholder: 'enter_your_email_please',type: 'email', required: ''},
        {id: 2, fieldName: 'Mobile', fieldPlaceholder: 'enter_your_mobile_phone_please',type: 'text', required: ''},
        {id: 2, fieldName: 'Message', fieldPlaceholder: 'enter_your_message_please',type: 'text', required: ''},
        {id: 3, fieldName: 'Robot test', fieldPlaceholder: 'answer_the_question_please',type: 'text', required: ''}
    ];
    const name = 'contact_us'
    const btnText = 'sent_message'
    const reqFields = ['Fullname', 'Email', 'Mobile', 'Message', 'Robot test'];
    const [status, setStatus] = useState([]);
    const [values, setValues] = useState({});
    const [userMessage, setUserMessage] = useState({})

    function onChangeInput(e){
        setValues(prev=>({...prev ,[e.target.name] : e.target.value.trim()}));
    }

    function checkForm(e){
        e.preventDefault();
        const checkField = checkFields();
        if(checkField){
            setUserMessage(values);
            setInterval(()=>{
                // history.push('/');
                setUserMessage({});
            }, 3000);        
        }
    }

    function checkFields(){
        setStatus([]);
        let errors = [];
        reqFields.map(field=>{
            if(values[field]===undefined || values[field]===''){
                if(field !== 'Phone')
                    errors.push(field);
            }
        });
        if(errors.length>0){
            setStatus(errors);
            return false
        }
        return true;
    }
    
// Give data from database, it is temperary
    useEffect(()=>{
        fetchDataFromDB();
    }, []);
    const fetchDataFromDB = async() => {
        const data = await fetch('/contact');
        const items = await data.json();
        // console.log(items);
    };
// End of giving data from database 

    return (
        <div>
           {fields && <Form name={name} fields={fields} checkForm={checkForm} onChange={onChangeInput} status={status} userMessage={userMessage} btnText={btnText} />} 
        </div>
    )
}

export default Contact
