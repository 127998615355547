import React, { useState, useEffect, Suspense } from 'react'
import { useLocation } from 'react-router';
import useStyles from './styles';
import logo from '../../assests/images/logo.jpg';
import mobileLogo from '../../assests/images/mobileLogo.jpg';
import LargeScreenList from './LargeScreenList';
import MobileScreenList from './MobileScreenList';
import ResizeListener from './ResizeListener';
import Button from '@material-ui/core/Button';
// import Icon from '@material-ui/core/Icon';
import {LngDropdown, Loading} from '../../components';
import { useTranslation } from 'react-i18next';

const Navbar = ({languageOptions}) => {

    const { t } = useTranslation()

    const location = useLocation();
    const adminPathname = location.pathname.slice(1, 6) || ''
    const styles = useStyles();
    const Links = {categories: 'categories'}
    const tabs = {
        'All_Ads':'/',
        'consulting':'/Consulting',
    };
    let screenWidth = ResizeListener();

    let [isMobile, setIsMobile] = useState(false);

    useEffect(()=>{
        if(screenWidth<=1125)
            setIsMobile(true);
        else
            setIsMobile(false);
    },[screenWidth]);

    return (
        // <Suspense fallback={<Loading />}> 
            <div className={styles.navbarRoot}>
                {(adminPathname !== 'admin') &&
                    <div className={styles.navbarMain} style={{height: (isMobile ? '61px' : 'auto')}}>
                        <div className={styles.logoAndList}>
                            {!isMobile && <LargeScreenList tabs={tabs} logo={logo} />}
                            {isMobile && <MobileScreenList tabs={tabs} logo={mobileLogo} languageOptions={languageOptions} /> }
                        </div>
                        <div className={styles.sendAds}>
                            {!isMobile && <LngDropdown languageOptions={languageOptions} width='102px' />}
                            <Button href='/SendAds' variant="contained" className={styles.SendButton} disableElevation>{t(`Send_Ads`)}</Button>
                        </div>
                    </div>
                }
            </div>
        // </Suspense>
    )
}

export default Navbar
