import React from 'react'

const Footer = () => {
    const style = {
        padding: '10px 0',
        textAlign: "center",
        fontFamily: 'poppine',
        // boxShadow: '1px 1px 10px grey',
        fontSize: 13,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        color: '#7a7a7a',
        maxWidth: 1350
    }
    return (
        <div style={{padding: '25px 0'}}>
            <div style={style}>
                All Rights Reserved &copy; 2022 - Designed By <span style={{color: '#1976d2'}}>Nosima</span>
            </div>
        </div>
    )
}

export default Footer
