import React, {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import {Ads} from '../../components'
import axios, { Axios } from 'axios';
import {Loading} from '../../components'

const MyAds = () => {

    const [isLoading, setIsLoading] = useState(true)

    const user = useContext(UserContext).cookies.loginData.email || ''
    const FETCH_URL = useContext(UserContext).FETCH_URL

    const [userAdverts, setUserAdverts] = useState([])
    const fetchUserAdverts = async () => {
        const initData = await fetch(`${FETCH_URL}/userAds/?user=${user}`);
        const result = await initData.json();
        setUserAdverts(result)
        setIsLoading(false)
    }

    useEffect(()=>{
        if(user.length){
            fetchUserAdverts()
        }
    }, [])

    async function deleleItem(id){
        if(id){
            const deleteId = id
            const deletingItem = await axios.delete(`${FETCH_URL}/delAd`, {data: {'id':deleteId}})
            .then((response)=>{fetchUserAdverts()})
            .catch(err=>console.log(err))
        }
    }
    
    return (
        <div>
            {isLoading && <Loading />}
            {userAdverts.length!==0 && <Ads advertisements={userAdverts} user={user} deleleItem={deleleItem} noPagination={true} />}
        </div>
    )
}

export default MyAds
