import axios from 'axios';
import React , {createContext, useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';

export const adminContext = createContext();

const AdminContextProvider = (props) => {
    
    const FETCH_URL = 'https://tymir.onrender.com';
    // const FETCH_URL = 'https://api.tym.ir';
    // const FETCH_URL = 'http://localhost:4000';

// admin cookies
    const [cookies, setCookie, removeCookie] = useCookies(['adminData']);
    function setCookieData(name, values, path='/', skipTime=86000000){
        if(name && values){
            let expireDate = new Date()
            expireDate.setTime(expireDate.getTime() + (skipTime));
            setCookie(name, values, {path: path, expires: expireDate});
        }else{
            console.log('Admin cookies are not set')
        }
    }
    function removeCookieData(name='', path='/'){
        if(name){
            removeCookie(name, { path: path })
            return true
        }else{
            console.log('Cannot remove admin cookies!')
            return false
        }
    }

// update ad status by admin
    const [statusChanged, setStatusChanged] = useState('')
    async function updateAdStatus(id, status){
        const updateStatus = axios.put(`${FETCH_URL}/updateAdStatus`, {id: id, status: status})
        .then(res=>console.log('result is ',res))
        .catch(err=>console.log('error is ',err))
        .then(()=>setStatusChanged(`${id}-${status}`))
    }

// Update admin data
    async function updateAdminData(params){
        const updatingAdmin = axios.put(`${FETCH_URL}/updateAdmin`, params)
        .then(response=>response.status)
        .catch(err=>err)
        return updatingAdmin
    }

// Context values
    const context={
        cookies: cookies,
        setCookieData: setCookieData,
        removeCookieData: removeCookieData,
        updateAdStatus: updateAdStatus,
        statusChanged: statusChanged,
        updateAdminData: updateAdminData,
        FETCH_URL:FETCH_URL
    }

    return (
        <adminContext.Provider value={context}>
            {props.children}
        </adminContext.Provider>
    )
}

export default AdminContextProvider
