import React, {useState, useEffect, useContext} from 'react'
import { Redirect, useLocation, useHistory} from 'react-router';
import { UserContext } from '../../../context/UserContext';
import { DropDown, UpdateAdsFileUpload } from '../../../components'
import ShowLocation from '../sendAds/ShowLocations';
import ShowCheckBox from '../sendAds/ShowCheckBox'
import TextField from '@material-ui/core/TextField';
import '../sendAds/styles.css'
import axios from 'axios';
import RedirecModal from '../../../templates/Modals/RedirecModal';
import { useTranslation } from 'react-i18next';

const UpdateAds = () => {

    const {t} = useTranslation();
    const context = useContext(UserContext)
    const FETCH_URL = context.FETCH_URL
// user from cookie
    const userEmail = context.cookies.loginData ? context.cookies.loginData.email : ''
// context values
    const locations = useContext(UserContext).locations;
// History to redirecting after update
    const history = useHistory()
// Check if updating progress is running
    const [updatingProgress, setUpdatingProgress] = useState(false)
// Check All data from CurrentAd resieved
    const [allDataResieved, setAllDataResieved] = useState(false)
// current Ad
    const [currentAd, setCurrentAd] = useState({})
    const location = useLocation()
    const id = location.pathname.split('/')[2];
// errors to display
    const [errors, setErrors] = useState([]);
    const [logicErrors, setLogicErrors] = useState([]);
// initial location states
    const [provinceSelected, setProvinceSelected] = useState('');
    const [citySelected, setCitySelected] = useState('');
    const [neighborhoodSelected, setNeighborhoodSelected] = useState('');
// dropdown inputs
    const [type, setType] = useState('')
    const [kindOfProperty, setKindOfProperty] = useState('')
    const [bed, setBed] = useState('')
    const [size, setSize] = useState('')
    const [address, setAddress] = useState('')
    const [floor, setFloor] = useState('')
    const [overallFloor, setOverallFloor] = useState('')
    const [overallUnit, setOverallUnit] = useState('')
    const [price, setPrice] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
// checkbox input
    const initialFeatures = ['elevator', 'pet-friendly', 'Furnished', 'Parking-Space', 'Electricity-Backup','Air-Conditioned','Central-Heating', 'Double-Glazed-Windows', 'Balcony', 'Lobby', 'Gym', 'Pool', 'Kids-Play-Area']
    const [features, setFeatures] = useState({})
    const [featuresToSend, setFeaturesToSend] = useState({})
    const kindOfPropertyItems = ['apartment', 'house', 'official place', 'store'];
// File and Image to upload from temp folder to upload folder
    const [hidedImagesFromThumbShow, setHidedImagesFromThumbShow] = useState([])
    const [uploadedTempFiles, setUploadedTempFiles] = useState([]);
    const [uploadingTemp, setUploadingTemp] = useState(false)

// set data in features
    useEffect(()=>{
        var temp = {}
        if(features.length){
            features.map(f=>{
                const item = f.value?f.value:f
                temp[item]= 'true'
            })
        }
        setFeaturesToSend(temp)
    },[features])

// Fetching current Ad from DB 
    const fetchingAd = async () => {
        const initData = await fetch(`${FETCH_URL}/ad/?id=${id}`);
        const result = await initData.json();
        setCurrentAd(result)
    }
    useEffect(()=>{
        if(id && !Object.keys(currentAd).length){
            fetchingAd();
        }
    }, [])
    useEffect(()=>{
        if(Object.keys(currentAd).length){
            setType(currentAd.type)
            setKindOfProperty(currentAd.kindOfProperty)
            setBed(currentAd.bed)
            setSize(currentAd.size)
            setAddress(currentAd.address)
            setFloor(currentAd.floor)
            setOverallFloor(currentAd.overallFloor)
            setOverallUnit(currentAd.overallUnit)
            setPrice(currentAd.price)
            setTitle(currentAd.title)
            setDescription(currentAd.description)
            setFeatures(Object.keys(currentAd.features)?Object.keys(currentAd.features):{})
            setHidedImagesFromThumbShow(currentAd.adsFiles)

            setAllDataResieved(true)
        }
    }, [currentAd])

// collect all the data
    const [sendValues, setSendValues] = useState({})
    useEffect(()=>{
        if(userEmail!=='undefined'){
            setSendValues(prev=>({
                ...prev,
                province: provinceSelected,
                city: citySelected,
                neighborhood: neighborhoodSelected,
                type: type,
                size: Number(size),
                title: title.trim(),
                description: description,
                kindOfProperty: kindOfProperty,
                bed: bed,
                price: Number(price),
                features: featuresToSend,
                floor: floor,
                overallFloor: overallFloor,
                overallUnit: overallUnit,
                address: address?address.trim():address,
            }))
        }
    },[provinceSelected,citySelected,neighborhoodSelected,kindOfProperty,bed,featuresToSend,type,size,title,price,description,floor,overallFloor,overallUnit,address])

// error creator
    function errorCreator(){
        if(errors){
            return (
                <div className='errorsDiv'>
                    <div className='errorTitle'>{t('please_complete')}</div>
                    {errors.map(e=><div><i>{t(`${e}`)}</i></div>)}
                </div>
            )
        }
    }
    function logicErrorCreator(){
        if(logicErrors.length){
            return (
                <div className='errorsDiv'>
                    <div className='errorTitle'>{t('please_correct_items')} </div>
                    {logicErrors.map(e=><div><i>{t(`${e}`)}</i></div>)}
                </div>
            )
        }
    }
// check the form for error
    const requiredFields = ['province','city','neighborhood','kindOfProperty','type','size','title','description']
    function checkForm(){
        let err = []
        if(!Object.keys(sendValues).length){
            return false;
        }
        else{
            requiredFields.map(req=>{
                if(sendValues[req]==='' || sendValues[req]===0 || typeof(sendValues[req])==='undefined')
                    err.push(req)
            })
        }
        setErrors(err)
        if(err.length) return false
        else return true
    }
// check the form for logic error
    function logicCheckForm(){
        let logicErr = []
        if(sendValues.user==='') logicErr.push(t('username_not_found_please_login_again'));
        if(!kindOfPropertyItems.includes(sendValues.kindOfProperty)) logicErr.push(t('kind_of_property_is_not_correct'))
        if(sendValues.type!=='sell' && sendValues.type!=='buy') logicErr.push(t('type_is_not_correct'))
        if(sendValues.title.length < 6) logicErr.push(t('title_field_length_must_be_at_least_6_characters'));
        if(sendValues.description.length < 10) logicErr.push(t('minimun_description_length_must_be_10_characters'));
        if(typeof(sendValues.address)!=='undefined' && sendValues.address!==null && sendValues.address.length > 30) logicErr.push(t('maximun_address_field_length_must_be_30_characters'));
        if(sendValues.size > 9999) logicErr.push(t('size_cannot_be_more_than_9999_square_meter'))
        if(sendValues.price === NaN) logicErr.push('price value is not correct!')
        if(sendValues.size === NaN) logicErr.push('size value is not correct!')

        setLogicErrors(logicErr)
        if(logicErr.length) return false;
        else return true
    }
// Final checking data 
    function finalCheck(){
        const items = ['floor', 'overallFloor', 'overallUnit', 'address']
        const finalDataToSend = sendValues;
        if(sendValues.type==='buy'){
            // items.map(i=>{setSendValues(prev=>({...prev, [i]: null}))})
            items.map(i=>{finalDataToSend[i] = null})
            return finalDataToSend
        }
        if(sendValues.type==='sell'){
            // items.map(i=>{if(!sendValues[i]) setSendValues(prev=>({...prev, [i]: null}))})
            items.map(i=>{if(!finalDataToSend[i]) finalDataToSend[i] = null})
            return finalDataToSend
        }else{ 
            return false
        }
    }
// Check for similar items  - if there is no change return NO CHANGE
    function similarItemCheck(finalCheckedData, adFiles){
        const removeSimilar = Object.assign({}, finalCheckedData)
        if(currentAd.adsFiles !== adFiles) removeSimilar.adsFiles = adFiles
        Object.keys(removeSimilar).map(item=>{
            if(item==='features'){
                const oldFeatures = Object.keys(currentAd.features)
                const newFeatures = Object.keys(removeSimilar.features)
                if(JSON.stringify(oldFeatures) === JSON.stringify(newFeatures))
                    delete removeSimilar.features
            }
            if(removeSimilar[item] === currentAd[item])
                delete removeSimilar[item]
        })
        return removeSimilar;
    }

// Remove images from upload folder and upload files from temp to upload folder
    // (2)
    async function removeFilesFromUploadFolder(){
        if(currentAd.adsFiles !== hidedImagesFromThumbShow){  
            const filesToRemove = currentAd.adsFiles.filter(file=>{ return !hidedImagesFromThumbShow.includes(file)})
            const removeFilesFromUploadFolderByDB = filesToRemove.map(file=>{
                return axios.delete(`${FETCH_URL}/deleteFromUploadFolder`, {data: {filename: file}})
                .then(res=>{console.log(res.data); return res.data})
                .catch(err=>false)
            })
            const resultRemoveFromUploadFolder = Promise.all(removeFilesFromUploadFolderByDB).then(res=>{console.log(res); return res})
            return resultRemoveFromUploadFolder
        }else return currentAd.adsFiles
    }
    // (3)
    async function uploadFilesToUploadFolder(){
        const result = uploadedTempFiles.map(file=>{
            return axios.post(`${FETCH_URL}/uploadFile`, {filepath: file.filepath})
            .then(res=> res.data)
            .catch (err=>(false))
        })
        const fileNames = Promise.all(result).then(res=>{console.log('promise', res); return res})
        return fileNames
    }
    // (1)
    async function checkForChangingFiles(){
        const checkForRemoveFileFromUpload = await removeFilesFromUploadFolder()

        if(checkForRemoveFileFromUpload){

            let resultFromRemoveUploadFunction = []
            if(checkForRemoveFileFromUpload !== currentAd.adsFiles) {resultFromRemoveUploadFunction = currentAd.adsFiles.filter(item=>{return !checkForRemoveFileFromUpload.includes(item)})}
            else {resultFromRemoveUploadFunction = checkForRemoveFileFromUpload}

            const checkForUploadTempToUploadFolder = await uploadFilesToUploadFolder()
            if(checkForUploadTempToUploadFolder){
                return resultFromRemoveUploadFunction.concat(checkForUploadTempToUploadFolder)
            }
        }
        return false
    }

// Send ads to DB
    async function updateAd(e){
        e.preventDefault();
        setErrors([])
        setLogicErrors([])
        const formError = checkForm();
        if(formError){
            const formLogicError = logicCheckForm();
            if(formLogicError){
                const finalCheckedData = await finalCheck();
                if(finalCheckedData){
                    const fileManaging = await checkForChangingFiles()
                    if(fileManaging){
                    const uniqeItems = await similarItemCheck(finalCheckedData, fileManaging);
                    if(Object.keys(uniqeItems).length){
                        uniqeItems.status = 'pending';
                        setUpdatingProgress(true)
                            const sendingAdsData = axios.put(`${FETCH_URL}/updateAd`, {data: uniqeItems, id: currentAd._id})
                            .then(response=>{
                                // console.log(response)
                                const redirecting = setInterval(()=>{
                                    history.push('/panel');
                                    clearInterval(redirecting)
                                }, 3000); 
                            })
                            .catch(err=>{
                                // console.log(err)
                                setUpdatingProgress(false)
                                setLogicErrors(['update_went_wrong'])
                            })
                        }
                    }else{ setLogicErrors(['no_change_to_update_advertise']); setUpdatingProgress(false) }
                }else{ console.log('final check error!'); setUpdatingProgress(false) }
            }else{ console.log('logic Error!'); setUpdatingProgress(false) }
        }else{ console.log('formError!'); setUpdatingProgress(false) }
    }

    return (
        <React.Fragment>
            {(Object.keys(currentAd).length && userEmail===currentAd.user && allDataResieved) &&
            <div className='mainDiv'>
                {updatingProgress && <RedirecModal msg={t('your_changes_are_set_successfully')} happening={t('redirecting_to_my_ads')} />}
                <p id='titleText'>{t('modify_advertise')}</p>
                {locations && 
                <form method='POST' key='form' onSubmit={e=>updateAd(e)}>
                    <div className="container">
                        <ShowLocation locations={locations} provinceSelected={provinceSelected} setProvinceSelected={setProvinceSelected}citySelected={citySelected} setCitySelected={setCitySelected}neighborhoodSelected={neighborhoodSelected} setNeighborhoodSelected={setNeighborhoodSelected} currentAdLocation={{province: currentAd.province,city: currentAd.city,neighborhood: currentAd.neighborhood}} />
                        <div className='textFieldDiv'><DropDown name='type' options={[currentAd.type]} select={currentAd.type} handleSelect={setType} width='100%' disable={true} /></div>
                        <div className='textFieldDiv'><DropDown name='kind_of_property' options={kindOfPropertyItems} select={kindOfProperty} handleSelect={setKindOfProperty} width='100%' /></div>
                        {currentAd.type==='sell' && <div className='textFieldDiv'><TextField id="address" label={t(`address`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>setAddress(e.target.value)} value={address} /></div>}
                        <div className='textFieldDiv'><DropDown name='bedroom' options={[0,1,2,3,4,5,6,7,8,9,10]} select={bed} handleSelect={setBed} width='100%' /></div>
                        <div className='textFieldDiv'><TextField id="size" value={size} type='number' label={t('size_meter')} style={{width:'100%'}} onChange={(e)=>setSize(e.target.value)} value={size} /></div>
                        {currentAd.type==='sell' && <div className='textFieldDiv'><TextField id="floor" value={floor} type='number' label={t(`floor`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>setFloor((e.target.value))} /></div>}
                        {currentAd.type==='sell' && <div className='textFieldDiv'><TextField id="overallFloor" value={overallFloor} type='number' label={t(`overall_floor`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>setOverallFloor((e.target.value))} /></div>}
                        {currentAd.type==='sell' && <div className='textFieldDiv'><TextField id="overallUnit" value={overallUnit} type='number' label={t(`overall_unit`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>setOverallUnit((e.target.value))} /></div>}
                        <div className='textFieldDiv'><TextField id="price" value={price} type='number' label={t(`send_ad_price`)} style={{width:'100%'}} onChange={(e)=>setPrice((e.target.value))} /></div>
                        <div className='textFieldDiv'><TextField id="title" value={title} label={t('ad_title')} style={{width:'100%'}} onChange={(e)=>setTitle(e.target.value)} /></div>
                        <div className='textFieldDiv'><label style={{fontSize: '1rem', fontFamily: 'Roboto', color: '#0000008a'}}>{t('description')}<textarea id='description' value={description} name="description" rows="10" style={{width: '98%'}} onChange={(e)=>setDescription(e.target.value)}></textarea></label></div>
                        <ShowCheckBox initialFeatures={initialFeatures} setFeatures={setFeatures} features={features} />
                        <UpdateAdsFileUpload 
                            currentFiles={currentAd.adsFiles}
                            hidedImagesFromThumbShow={hidedImagesFromThumbShow}
                            setHidedImagesFromThumbShow={setHidedImagesFromThumbShow}
                            uploadedTempFiles={uploadedTempFiles}
                            setUploadedTempFiles={setUploadedTempFiles}
                            setUploadingTemp={setUploadingTemp}
                        />
                        {errors.length ? errorCreator() : ''}
                        {logicErrors.length ? logicErrorCreator() : ''}
                        <div className='btnDiv'>
                            <button className='submitBtn' type='submit' disabled={updatingProgress} >{t('update_advertise')}</button>
                        </div>
                    </div>
                </form>}
            </div>
            }
        </React.Fragment>
    )
}

export default UpdateAds
