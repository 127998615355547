import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';

export default makeStyles((theme)=>({
    mainProductDiv:{
        fontFamily: 'poppine',
        boxShadow: '0px 0px 20px 3px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    media:{
        width: '100%',
        height: '200px',
    },
    cardContent:{
        display: 'flex',
        justifyContent: 'space-between',
        '& p': {
            fontFamily: 'poppine',
            fontSize: '13px',
        }
    },
    cardActions:{
        display: 'flex',
        justifyContent: 'space-between',
    },
    adTitle:{
        maxWidth: '160px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    description: {
        '& p':{
            minHeight: '48px',
            marginBottom: 0,
        }
    },
    iconDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 25,
        '& p':{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        }
    },
    userBtns: {
        padding: '8px',
        display: 'block',
        '& a':{
            width: '100%',
            marginBottom: '10px'
        },
        '& button': {
            width: '100%',
            marginBottom: '10px'
        }
    },
    badge:{
        padding: '5px',
        textAlign: 'center',
        color: 'white',
    },
    detailsBtn:{
        textDecoration: 'none',
        border: '1px solid #bababa',
        position: 'relative',
        overflow: 'hidden',
        fontSize: '14px',
        padding: '7px 15px',
        textTransform: 'uppercase',
        transition: 'all .5s',
        color: '#e91e63',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: '-100%',
            width: '100%',
            height: '100%',
            background: 'linear-gradient(120deg,transparent,rgba(146, 148, 248, 0.4),transparent)',
            transition: 'all 650ms',
        },    
        '&:hover': {
            boxShadow: '1px 1px 25px 3px rgba(146, 148, 248, 0.4)',
            '&::before': {
                left: '100%',
            }
        }
    }
    

}))