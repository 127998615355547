import React, {useState, useEffect, useContext} from 'react'
import { Redirect, useHistory } from 'react-router';
import { UserContext } from '../../../context/UserContext';
import { DropDown, Loading, FileUpload } from '../../../components';
import RedirecModal from '../../../templates/Modals/RedirecModal'
import ShowLocation from './ShowLocations';
import ShowCheckBox from './ShowCheckBox'
import TextField from '@material-ui/core/TextField';
import './styles.css'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'

const SendAds = () => {

// get current language
    const curLang = cookies.get('i18next')

    const context = useContext(UserContext)
    const FETCH_URL = context.FETCH_URL
// history
    const history = useHistory()
// translation 
    const {t} = useTranslation()
// user from cookie
    const user = context.cookies
// context values
    const locations = context.locations;
// errors to display
    const [errors, setErrors] = useState([]);
    const [logicErrors, setLogicErrors] = useState([]);
    const [adSentSuc, setAdSentSuc] = useState(false)
// show loading for progress
    const [isLoading, setIsLoading] = useState(false)
// initial location states
    const [provinceSelected, setProvinceSelected] = useState('');
    const [citySelected, setCitySelected] = useState('');
    const [neighborhoodSelected, setNeighborhoodSelected] = useState('');
// dropdown inputs
    const [kindOfProperty, setKindOfProperty] = useState('')
    const [bed, setBed] = useState('')
    const [type, setType] = useState('')
// checkbox input
    const initialFeatures = ['Elevator', 'Pet-friendly', 'Furnished', 'Parking-Space', 'Electricity-Backup','Air-Conditioned','Central-Heating', 'Double-Glazed-Windows', 'Balcony', 'Lobby', 'Gym', 'Pool', 'Kids-Play-Area']
    const [features, setFeatures] = useState({})
    const [featuresToSend, setFeaturesToSend] = useState({})
    const kindOfPropertyItems = ['apartment', 'house', 'official-place', 'store'];
// File and Image to upload from temp folder to upload folder
    const [uploadedTempFiles, setUploadedTempFiles] = useState([]);
    const [uploadingTemp, setUploadingTemp] = useState(false)
// set data in features
    useEffect(()=>{
        var temp = {}
        if(features.length){
            features.map(f=>{
                temp[f.value] = 'true'
            })
        }
        setFeaturesToSend(temp)
    },[features])

// collect all the data
    const [sendValues, setSendValues] = useState({})
    function saveValues(e){
        const id = e.target.id
        let val = e.target.value
        if(id==='floor'||id==='size'||id==='overallFloor'||id==='overallUnit'||id==='price'||id==='bed')
            val = Number(val)
            
        setSendValues(prev=>({...prev, [id]: val}))
    }
    useEffect(()=>{
        if(user.loginData!=='undefined'){
            setSendValues(prev=>({
                ...prev,
                user: user.loginData ? user.loginData.email : '',
                country: 'Turkey',
                province: provinceSelected,
                city: citySelected,
                neighborhood: neighborhoodSelected,
                kindOfProperty: kindOfProperty,
                bed: bed,
                type: type,
                date: Date.now(),
                features: featuresToSend,
            }))
        }
    },[provinceSelected,citySelected,neighborhoodSelected,kindOfProperty,bed,type,featuresToSend])

// error creator
    function errorCreator(){
        if(errors){
            return (
                <div className='errorsDiv'>
                    <div className='errorTitle'>{t('please_complete')}</div>
                    {errors.map(e=><div><i>{t(`${e}`)}</i></div>)}
                </div>
            )
        }
    }
    function logicErrorCreator(){
        if(logicErrors.length){
            return (
                <div className='errorsDiv'>
                    <div className='errorTitle'>{t('please_correct_items')} </div>
                    {logicErrors.map(e=><div><i>{t(`${e}`)}</i></div>)}
                </div>
            )
        }
    }
// check the form for error
    const requiredFields = ['province','city','neighborhood','kindOfProperty','type','size','title','description']
    function checkForm(){
        setErrors([])
        let err = []
        if(!Object.keys(sendValues).length){
            return false;
        }
        else{
            requiredFields.map(req=>{
                if(sendValues[req]==='' || sendValues[req]===0 || typeof(sendValues[req])==='undefined')
                    err.push(req)
            })
        }
        setErrors(err)
        if(err.length) return false
        else return true
    }
// check the form for logic error
    function logicCheckForm(){
        setLogicErrors([])
        let logicErr = []
        if(sendValues.user==='') logicErr.push(t('username_not_found_please_login_again'));
        if(!kindOfPropertyItems.includes(sendValues.kindOfProperty)) logicErr.push(t('kind_of_property_is_not_correct'))
        if(sendValues.type!=='sell' && sendValues.type!=='buy') logicErr.push(t('type_is_not_correct'))
        if(sendValues.title.length < 6) logicErr.push(t('title_field_length_must_be_at_least_6_characters'));
        if(sendValues.title.length > 25) logicErr.push(t('title_field_length_must_be_less_than_25_characters'));
        if(sendValues.description.length < 10) logicErr.push(t('minimun_description_length_must_be_10_characters'));
        if(typeof(sendValues.address)!=='undefined' && sendValues.address!==null && sendValues.address.length > 30) logicErr.push(t('maximun_address_field_length_must_be_30_characters'));
        if(sendValues.size > 9999) logicErr.push(t('size_cannot_be_more_than_9999_square_meter'))
        setLogicErrors(logicErr)
        if(logicErr.length) return false;
        else return true
    }

// Final checking data 
    function finalCheck(){
        const items = ['floor', 'overallFloor', 'overallUnit', 'address']
        const finalDataToSend = sendValues;
        if(!sendValues.price || sendValues.price >= 0) sendValues.price = 0
        if(sendValues.type==='buy'){
            // items.map(i=>{setSendValues(prev=>({...prev, [i]: null}))})
            items.map(i=>{finalDataToSend[i] = null})
            return finalDataToSend
        }
        if(sendValues.type==='sell'){
            // items.map(i=>{if(!sendValues[i]) setSendValues(prev=>({...prev, [i]: null}))})
            items.map(i=>{if(!finalDataToSend[i]) finalDataToSend[i] = null})
            return finalDataToSend
        }else{ 
            return false
        }
    }

// Upload temp files to upload folder
    async function uploadFilesToUploadFolder(){
        const result = uploadedTempFiles.map(file=>{
            return axios.post(`${FETCH_URL}/uploadFile`, {filepath: file.filepath})
            .then(res=> res.data)
            .catch (err=>(false))
        })
        const fileNames = Promise.all(result).then(res=>res)
        return fileNames
    }

// Send ads to DB
    async function sendAds(e){
        e.preventDefault();
        setIsLoading(true)
        const formError = checkForm();
        if(formError){
            const formLogicError = logicCheckForm();
            if(formLogicError){
                const finalCheckedData = await finalCheck();
                if(finalCheckedData){
                    const uploadedFiles = await uploadFilesToUploadFolder()
                    if(uploadedFiles && !uploadedFiles.includes(false)){
                        const fileToUpload = uploadedTempFiles.map(f=>f.filename)
                        const sendingAdsData = axios.post(`${FETCH_URL}/sendAds`, {finalCheckedData, uploadedFiles: fileToUpload})
                        // .then(response=>{setAdSentSuc(response.data); console.log('sent', response)})
                        .then(response=>{console.log('sent', response)})
                        .catch(err=>{console.log(err)})
                    }else{ console.log('cannot upload files! please try again')}
                }else{ console.log('final check error!') }
            }else{ console.log('logic Error!') }
        }else{ console.log('formError!') }
        setIsLoading(false)
    }

    useEffect(()=>{
        if(adSentSuc){
            const redirectToPanel = setInterval(()=>{
                history.push('/panel')
                clearInterval(redirectToPanel)
            }, 3000)    
        }
    },[adSentSuc])

    function textareaClassChange(){
        if(curLang==='ir') return 'persian'
        return
    }
    
    return (
        <React.Fragment>
            {adSentSuc && <RedirecModal msg={t('your_ads_sent_successfully')} happening={t('redirecting')} />}
            {isLoading && <Loading />}
            {user.loginData ? 
            <div className='mainDiv'>
                <p id='titleText'>{t('please_complete_form')}</p>
                {locations && 
                <form method='POST' key='form' onSubmit={e=>sendAds(e)}>
                    <div className="container">
                        <ShowLocation locations={locations} provinceSelected={provinceSelected} setProvinceSelected={setProvinceSelected}citySelected={citySelected} setCitySelected={setCitySelected}neighborhoodSelected={neighborhoodSelected} setNeighborhoodSelected={setNeighborhoodSelected}/>
                        <div className='textFieldDiv'><DropDown name='type' options={['buy', 'sell']} select={type} handleSelect={setType} width='100%' /></div>
                        <div className='textFieldDiv'><DropDown name='kind_of_property' options={kindOfPropertyItems} select={kindOfProperty} handleSelect={setKindOfProperty} width='100%' /></div>
                        {type==='sell' && <div className='textFieldDiv'><TextField id="address" label={t(`address`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>saveValues(e)}/></div>}
                        <div className='textFieldDiv'><DropDown name='bedroom' options={[0,1,2,3,4,5,6,7,8,9,10]} select={bed} handleSelect={setBed} width='100%' /></div>
                        <div className='textFieldDiv'><TextField id="size" type='number' label={t('size_meter')} style={{width:'100%'}} onChange={(e)=>saveValues(e)} /></div>
                        {type==='sell' && <div className='textFieldDiv'><TextField id="floor" type='number' label={t(`floor`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>saveValues(e)} /></div>}
                        {type==='sell' && <div className='textFieldDiv'><TextField id="overallFloor" type='number' label={t(`overall_floor`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>saveValues(e)} /></div>}
                        {type==='sell' && <div className='textFieldDiv'><TextField id="overallUnit" type='number' label={t(`overall_unit`)+t(`optional`)} style={{width:'100%'}} onChange={(e)=>saveValues(e)} /></div>}
                        <div className='textFieldDiv'><TextField id="price" type='number' label={t(`send_ad_price`)} style={{width:'100%'}} onChange={(e)=>saveValues(e)} /></div>
                        <div className='textFieldDiv'><TextField id="title" label={t('ad_title')} style={{width:'100%'}} onChange={(e)=>saveValues(e)} /></div>
                        <div className='textFieldDiv'><label style={{fontSize: '1rem', fontFamily: 'Roboto', color: '#0000008a'}}>{t('description')}<textarea className={textareaClassChange()} id='description' name="description" rows="10" style={{width: '98%'}} onChange={(e)=>saveValues(e)}></textarea></label></div>
                        <ShowCheckBox initialFeatures={initialFeatures} setFeatures={setFeatures} placeholder='select_features' />
                        <FileUpload uploadedTempFiles={uploadedTempFiles} setUploadedTempFiles={setUploadedTempFiles} setUploadingTemp={setUploadingTemp} />
                        {errors.length ? errorCreator() : ''}
                        {logicErrors.length ? logicErrorCreator() : ''}
                        <div className='btnDiv'>
                            <button className='submitBtn' type='submit' disabled={adSentSuc || uploadingTemp}>{t(`send_advertise`)}</button>
                        </div>
                    </div>
                </form>}
            </div>
            : <Redirect to='/login/send' /> }
        </React.Fragment>
    )
}

export default SendAds
