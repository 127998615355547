import React from 'react'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import './styles.css';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const ShowCheckBox = ({initialFeatures, setFeatures, features, placeholder='select'}) => {

    const {t} = useTranslation()
    // change label to show translated features in send ads 11/12/2021
  
    const options = initialFeatures.map(f=>({value: f, label: t(`${f}`)}))

    const currentFeatures = features ? features.map(f=>({value: f, label: t(`${f}`)})) : ''

    const customStyles = {
        control: styles => ({ ...styles, minHeight: '48px' }),
    }

    return (
        <React.Fragment>
            {options && <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={currentFeatures}
                placeholder={t(`${placeholder}`)}
                isMulti
                onChange={setFeatures}
                options={options}
                styles={customStyles}
            />}
        </React.Fragment>
    )
}

export default ShowCheckBox
