import React, {useState, useEffect, useContext} from 'react';
import { useHistory, Redirect } from "react-router-dom";
import FiledsTemplate from '../templates/FieldsTemplate/FieldsTemplate';
import { UserContext } from '../context/UserContext';
import axios from 'axios'

const Register = ({setUserInfo}) => {

    const [values, setValues] = useState({});
    const [status, setStatus] = useState([]);
    const [logicStatus, setLogicStatus] = useState([]);
    const [isRegistered, setIsRegistered] = useState(false);
    const [userExists, setUserExists] = useState({user: false, email: false, mobile: false})
    let history = useHistory();

// Fields to send for register form
    const reqFields = ['Username','Email', 'Mobile', 'Password', 'Confirm password', 'Robot test'];

// Context    
    const question = useContext(UserContext).question
    const userCookie = useContext(UserContext).cookies;
    const setCookieData = useContext(UserContext).setCookieData
    const FETCH_URL = useContext(UserContext).FETCH_URL

// send user data to register in database
    async function checkForm(e){
        e.preventDefault()
        setUserInfo({})
        setUserExists({user: false, email: false, mobile: false})
        const checkField = checkFields()
        if(checkField && isRegistered===false){
            const checkLogicField = checkLogicFields();
            if(checkLogicField){
                const checkUserExists = await axios.get(`${FETCH_URL}/user/?username=${values.Username}&email=${values.Email}&mobile=${values.Mobile}`)
                .then(response=>{
                    if(response.data===null){
                        values.fav = [];
                        const registerInDB = axios.post(`${FETCH_URL}/register`, {values})
                        .then(res=>{
                            setCookieData('loginData', {email: values.Email, username: values.Username, mobile: values.Mobile, fav: values.fav})
                            setIsRegistered(res.data)
                            const redirecting = setInterval(()=>{
                                history.push('/');
                                clearInterval(redirecting)
                            }, 3000);  
                        })
                        .catch(error=>{console.log(error)})
                    }else if(response.data!==null && response.data.username==values.Username){
                        setUserExists({user: true, email: false, mobile: false})
                    }else if(response.data!==null && response.data.email==values.Email){
                        setUserExists({user: false, email: true, mobile: false})
                    }else if(response.data!==null && response.data.mobile==values.Mobile){
                        setUserExists({user: false, email: false, mobile: true})
                    }
                })
                .catch(error=>console.log(error))
            }
        }
    }

// check empty or wrong fields
    function checkFields(){
        setStatus([]);
        setLogicStatus([])
        let errors = [];
        reqFields.map(field=>{
            if(values[field]===undefined || values[field]==='')
                errors.push(field);
        });
        if(errors.length>0){
            setStatus(errors);
            return false
        }
        return true;
    }

// check fields for logic error
    function checkLogicFields(){
        let logicErrors = [];
        if(values){
            if(values.Username.length < 6) logicErrors.push('Username');
            if(values.Mobile.length < 11) logicErrors.push('Mobile');
            else if(checkMobile(values.Mobile)===false) logicErrors.push('Mobile');
            if(values.Password.length < 7) logicErrors.push('Password')
            if(values.Password !== values['Confirm password']) logicErrors.push('Confirm password');
            if(Number(values['Robot test']) !== question.answer) logicErrors.push('Robot test');
            if(logicErrors.length>0) {setLogicStatus(logicErrors); return false}
            else{return true}
        }
    }

    function checkMobile(Mobile){
        var checkReg = /^\d+$/;
        if(Mobile.match(checkReg)) return true
        else return false
    }

    function onChangeInput(e){
        setValues(prev=>({...prev ,[e.target.name] : e.target.value.trim()}));
    }

    const fields = [
        {id: 1, fieldName: 'Username', fieldPlaceholder: 'enter_your_Username_please',type: 'text', required: '', length: '',},
        {id: 2, fieldName: 'Email', fieldPlaceholder: 'enter_your_email_please',type: 'email', required: '', length: '9',},
        {id: 3, fieldName: 'Mobile', fieldPlaceholder: 'enter_your_mobile_phone_please',type: 'text', required: '', length: '11',},
        {id: 4, fieldName: 'Password', fieldPlaceholder: 'enter_your_password_please',type: 'password', required: '', length: '',},
        {id: 5, fieldName: 'Confirm password', fieldPlaceholder: 'enter_your_password_again_please',type: 'password', required: '', length: '',},
        {id: 6, fieldName: `Robot test`, fieldPlaceholder: 'answer_the_question_please',type: 'text', required: '', length: '',},
    ];
    const name = 'Register';

    return (
        <div>
            {!userCookie.loginData ? 
            <FiledsTemplate 
                name={name} 
                fields={fields} 
                checkForm={checkForm} 
                onChange={onChangeInput} 
                status={status} 
                logicStatus={logicStatus}
                isRegistered={isRegistered} 
                question={question.q}
                userExists={userExists}
            />
            : <Redirect to='/panel' />}
        </div>
    )
}

export default Register
