import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';

export default makeStyles((theme)=>({
    mainProductDiv:{
        fontFamily: 'poppine',
        boxShadow: 'inset 0px 0px 20px 0px rgb(0 0 0 / 20%), inset 0px 1px 1px 0px rgb(0 0 0 / 14%), inset 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    cardContent:{
        display: 'flex',
        justifyContent: 'space-between',
        '& p': {
            // fontFamily: 'poppine',
            lineHeight: '35px',
            fontSize: '18px',
        }
    },
    contactBtn: {
        margin: '10px 0',
        width: '100%',
    },
    contactDetails: {
        width: '100%',
        marginTop: '20px',
        transition: 'all .5s',
        '& div': {
            display: 'flex',
            marginBottom: '20px'
        },
    },
    details: {
        fontWeight: 'bold',
    },
    contactItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    featureBtn:{
        margin: '10px 0 0 0',
        width: '100%',
        borderRadius: '4px 4px 0 0'
    },
    featuresDiv:{
        backgroundColor: '#dbe1ff',
        minHeight: '130px',
        transition: 'all .5s',
        borderRadius: '0 0 4px 4px',
        display: 'flex',
        justifyContent: 'center',
        padding: 30,
    }
}))