import React, {useState, useEffect, useContext} from 'react';
import {UserContext} from '../../context/UserContext';
import {Link, useLocation} from 'react-router-dom';
import useStyles from './styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DropDown, LngDropdown} from '../../components';
import { useTranslation } from 'react-i18next';

const MobileScreenList = ({tabs, logo, options, languageOptions}) => {

    const {t} = useTranslation()
    const styles = useStyles();
    const [showMenu , setShowMenu] = useState(false);
    // const [showSubMenu, setShowSubMenu] = useState(false);

    const user = useContext(UserContext).cookies

    const adminPathname = useLocation().pathname.slice(1, 6) || ''

    function deriveList(){
        var el = [];
        for(var tab in tabs){
            // el.push(<li key={tab} onClick={e=>toggleSubMenu(e)}>{t(`${tab}`)} {showSubMenu?<ExpandMoreIcon/>:<NavigateNextIcon />}
            //     <ul className={`${styles.subList}`} key={tab+'-ul'}>
            //         {tabs[tab].map(item=>(
            //             <li className={styles.subListLi} key={tab+'-'+item}>{t(`${item}`)}</li>
            //         ))}
            //     </ul>
            // </li>)
            el.push(<Link onClick={()=>setShowMenu(false)} to={tabs[tab]}><li key={tab}>{t(`${tab}`)}</li></Link>)
        }
        return el
    }

    // function toggleSubMenu(e){
    //     const subList = e.target.children[1];
    //     if(subList){
    //         const allSubMenus = document.querySelectorAll(`ul.${styles.subListShow}`);
    //         if(allSubMenus)
    //             allSubMenus.forEach(item=>{if(item != subList) {item.classList.remove(styles.subListShow)}});
    //         if(!subList.classList.contains(styles.subListShow)){
    //             subList.classList.add(styles.subListShow);
    //             setShowSubMenu(true)}
    //         else{
    //             subList.classList.remove(styles.subListShow);
    //             setShowSubMenu(false)}
    //     }
    // }

    useEffect(()=>{
        if(!showMenu){
            const allSubMenus = document.querySelectorAll(`ul.${styles.subListShow}`);
            if(allSubMenus)
                allSubMenus.forEach(item=>{item.classList.remove(styles.subListShow)});
        }
    },[showMenu])

    return (
        <div className={styles.ulDivMobile}> 
            {!showMenu && <MenuIcon fontSize='large' cursor='pointer' onClick={()=>{setShowMenu(true)}} />}
            {showMenu && <CloseIcon style={{ fontSize:'30px', cursor:'pointer', width: '35px', }} onClick={()=>{setShowMenu(false)}} />}
            {/* <Link to='/'>
                <div className={styles.logoDiv}  to='/' >
                    <img src={logo} alt='logo' />
                </div>
            </Link> */}
            <div className={`${styles.sideList} sideList ${showMenu?styles.show:''}`}>
                <ul>
                    {deriveList()}
                    <Link onClick={()=>setShowMenu(false)} to='/contact'><li key='Contact'>{t('Contact_us')}</li></Link>
                    {!user.loginData && <Link onClick={()=>setShowMenu(false)} to='/login'><li key='Login' >{t('Login')}</li></Link>}
                    {user.loginData && <Link onClick={()=>setShowMenu(false)} to='/panel'><li key='panel' >{t('Profile')}</li></Link>}
                    {/* <li><DropDown select={languageOptions.select} handleSelect={languageOptions.handleSelect} options={languageOptions.options} name={languageOptions.name} width='80px' /></li> */}
                    <li><LngDropdown languageOptions={languageOptions} width='110px' setShowMenu={setShowMenu} /></li>
                </ul>
            </div>
        </div>
    )
}

export default MobileScreenList
