import React , {useState, useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"

const LngDropdown = ({languageOptions, width, setShowMenu}) => {

    const classes = useStyles();

    const {t} = useTranslation()

    function lngName(code){
        switch(code){
            case 'gb': return 'English'
            case 'tr': return 'Türk'
            case 'de': return 'deutsch'
            case 'ir': return 'پارسی'
            case 'ar': return 'العربية'
            default: return ''
        }
    }

    return (
        <div>
            <FormControl className={classes.formControl} style={{width: width}}>
                <InputLabel id="demo-simple-select-label">{languageOptions.name}</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={languageOptions.select} onChange={(e)=>{languageOptions.handleLngSelect(e.target.value); if(setShowMenu) setShowMenu(false)}}>
                    {languageOptions.options.map(item=>(
                        <MenuItem value={item.name} key={item.name}>
                            <ReactCountryFlag countryCode={item.countryCode} svg style={{width: '20px',height: '20px',paddingRight: '5px'}} />
                            {lngName(item.name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default LngDropdown
