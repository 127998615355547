import React , {createContext, useState, useEffect} from 'react';
import querystring from 'querystring';
import { useCookies } from 'react-cookie';

export const UserContext = createContext();

const UserContextProvider = (props) => {

    // const FETCH_URL = 'http://35.228.25.188:8080';
    // const FETCH_URL = 'https://api.tym.ir';
    const FETCH_URL = 'https://tymir.onrender.com';
    // const FETCH_URL = 'http://localhost:4000';

// user cookies
    const [cookies, setCookie, removeCookie] = useCookies(['loginData']);
    function setCookieData(name, values, path='/', skipTime=86000000){
        if(name && values){
            let expireDate = new Date()
            expireDate.setTime(expireDate.getTime() + (skipTime));
            setCookie(name, values, {path: path, expires: expireDate});
        }else{
            console.log('Cookies are not set')
        }
    }
    function removeCookieData(name='', path='/'){
        if(name){
            removeCookie(name, { path: path })
            return true
        }else{
            console.log('Cannot remove cookies!')
            return false
        }
    }
    
//Get All locations From Database
    const [locations, setLocations] = useState([])
    const fetchLocations = async() => {
        const initData = await fetch(`${FETCH_URL}/locations`);
        const result = await initData.json();
        setLocations(result.area);
    }
    useEffect(()=>{
        fetchLocations();
    },[])

//Get user From Database
    const [user, setUser] = useState([])
    const fetchUser = async() => {
        const initData = await fetch(`${FETCH_URL}/user`);
        const result = await initData.json();
        setUser(result);
    }
    useEffect(()=>{
        fetchUser();
    },[])

//Get All ads From Database
    const [adverts, setAdverts] = useState([])
    const fetchAds = async() => {
        const initData = await fetch(`${FETCH_URL}/ads`);
        const result = await initData.json();
        setAdverts(result);
    }
    useEffect(()=>{
        fetchAds();
    },[])

//Get ad From Database
    const [ad, setAd] = useState([])
    const fetchAd = async() => {
        const initData = await fetch(`${FETCH_URL}/ad`);
        const result = await initData.json();
        setAd(result);
    }
    useEffect(()=>{
        fetchAd();
    },[])

// Create questions for login and register fields
    const questions = [ {q:"( 10 - 4 = ?)", answer: 6}, {q:"( 24 + 1 = ?)", answer: 25}, {q:"( 8 + 14 = ?)", answer: 22}, {q:"( 7 + 4 = ?)", answer: 11}, {q:"( 40 + 1 = ?)", answer: 41}]
    function getRandQuestion() {
        const randNo = Math.floor(Math.random() * questions.length);
        return questions[randNo];
    }
    const question = getRandQuestion()

    const context={
        user: user,
        adverts: adverts,
        ad: ad,
        locations: locations,
        question: question,
        cookies: cookies,
        setCookieData: setCookieData,
        removeCookieData: removeCookieData,
        FETCH_URL:FETCH_URL
    }
    
    return (
        <UserContext.Provider value={context}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;