import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
    gridRoot:{
        margin: '10px 0 50px 0',
        width: '100%',
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    
    deleteAlertDiv:{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        textAlign: 'center',
        backgroundColor: '#8080808c',
        zIndex: 1,
        fontFamily: 'poppine'
    }, 
    deleteAlertContent:{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '30px 0',
        width: '290px',
        backgroundColor: 'white',
        borderRadius: '5px',
    }, 
    deleteAlertMsg:{
        marginBottom: '25px',
    },
    deleteAlertBtns:{
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}))