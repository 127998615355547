import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import useStyles from './styles'
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ApartmentIcon from '@material-ui/icons/Apartment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GradeIcon from '@material-ui/icons/Grade';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import {DropDown, ShowCheckBox} from '../../components'
import {DropDownArray} from '../../components'
// import Slider from '@material-ui/core/Slider';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`} {...other}>
        {value === index && (
            <Box p={3}>
            <Typography>{children}</Typography>
            </Box>
        )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function sizeValueText(sizeValues) {
    return `${sizeValues} m2`;
}
function bedValueText(bedValues) {
    return `${bedValues}`;
}
function priceValueText(value) {
    return `${value} TL`;
}

export default function Filters(
    {   types,
        setTypes,
        initialKindOfPropertyItem,
        kindOfPropertyItem,
        setKindOfPropertyItem,
        sizeValues,
        setSizeValues,
        bedValues,
        setBedValues,
        priceValues,
        setPriceValues,
        initialFeatures,
        features,
        setFeatures,
        setReset,
        provinceOptions,
        provinceSelected,
        setProvinceSelected,
        cityOptions,
        citySelected,
        setCitySelected,
        neighborhoodOptions,
        neighborhoodSelected,
        setNeighborhoodSelected,
        }
    ) {

// translation
    const {t} = useTranslation()

    const sizeOptions = [
        [[1, 10000],'all'],
        [[1, 50],'10 \u2192 50'],
        [[50, 100],'50 \u2192 100'],
        [[100, 150],'100 \u2192 150'],
        [[150, 200],'150 \u2192 200'],
        [[200, 300],'200 \u2192 300'],
        [[300, 10000],'300+']
    ]
    const bedOptions = [
        [[-1, 10], 'all'],
        [0 ,'studio'],
        [1 ,'1'],
        [2 ,'2'],
        [3 ,'3'],
        [[4, 10],'3+']
    ]
    const priceOptions = [
        [[0, 1000000000],'all'],
        [[0, 2],'agreement'],
        [[1, 500000],'\u2192 500,000'],
        [[500000, 1000000],`500,000 \u2192 1,000,000`],
        [[1000000, 2000000],`1,000,000 \u2192 2,000,000`],
        [[2000000, 3000000],`2,000,000 \u2192 3,000,000`],
        [[3000000, 4000000],`3,000,000 \u2192 4,000,000`],
        [[4000000, 5000000],`4,000,000 \u2192 5,000,000`],
        [[5000000, 1000000000],'5,000,000 +']        
    ]
    const typeOptions = [
        ['sell', 'properties'],
        ['buy', 'clients'],
    ]

// States for onChange Sliders
    // const [size, setSize] = useState(sizeValues)
    // const [bed, setBed] = useState(bedValues)
    // const [price, setPrice] = useState(priceValues)
    // const handleSlidersChanges = (event, newValue) => {
    //     switch(event.target.id){
    //         case 'sizeSlider':
    //             setSize(newValue);
    //             break;
    //         case 'bedSlider':
    //             setBed(newValue);
    //             break;
    //         case 'priceSlider':
    //             setPrice(newValue);
    //             break;
    //         default:
    //     }
    // }
// To reset onChange Sliders values when press reset filters
    // useEffect(()=>{
    //     setSize(sizeValues)
    //     setBed(bedValues)
    //     setPrice(priceValues)
    // },[sizeValues, bedValues, priceValues])
    
    const classes = useStyles();
    const [showHide, setShowHide] = useState(t('hide'));
    const [expanded, setExpanded] = useState(true);
    const [value, setValue] = useState(0);
    
    const showHideWord = () => {
        const checking = document.getElementById('panel1a-header') ? document.getElementById('panel1a-header').getAttribute('aria-expanded') : null
        if(checking!=='true') {setShowHide('hide'); setExpanded(true)}
        else {setShowHide('show'); setExpanded(false)}
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // const typeHandleChange = (event) => {
    //     setTypes(event.target.value);
    // };
    const typeHandleChange = (event) => { // new for type
        setTypes(event);
    };
    // const KindOfPropertyHandleChange = (event) => {
    //     setKindOfPropertyItem({ ...kindOfPropertyItem, [event.target.name]: event.target.checked });
    // };
    const KindOfPropertyHandleChange = (event) => { //new for kindOfProperty
        if(!event.length) setKindOfPropertyItem(initialKindOfPropertyItem)
        else setKindOfPropertyItem(event.map(i=>i.value));
    };
    const sizeHandleChange = (event) => {
        setSizeValues(event)
    }
    const bedHandleChange = (event) => {
        setBedValues(event)
    }
    const priceHandleChange = (event) => {
        setPriceValues(event)
    }
    // const featureHandleChange = (event) => {
    //     setFeatures({ ...features, [event.target.name]: event.target.checked });
    // };
    const featureHandleChange = (event) => {
        setFeatures(event.map(i=>i.value));
    };

    return (
        <div className={classes.AccordionRoot}>
            <Accordion className={classes.accordion} expanded={expanded}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" onClick={()=>showHideWord()}>
                    <Typography className={classes.heading}>{t(`${showHide}`)} {t('filters')} <SearchIcon /></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" aria-label="scrollable force tabs" className={classes.tabs}>
                                <Tab label={t("type")} icon={<ApartmentIcon />} {...a11yProps(4)} />
                                <Tab label={t("location")} icon={<LocationOnIcon />} {...a11yProps(4)} />
                                <Tab label={t("kind_of_property")} icon={<HomeWorkIcon />} {...a11yProps(0)} />
                                <Tab label={t("size")} icon={<SquareFootIcon />} {...a11yProps(1)} />
                                <Tab label={t("bedroom")} icon={<LocalHotelIcon />} {...a11yProps(2)} />
                                <Tab label={t("price")} icon={<MonetizationOnIcon />} {...a11yProps(3)} />
                                <Tab label={t("features")} icon={<GradeIcon />} {...a11yProps(5)} />
                                <Tab label={t("reset")} icon={<RotateLeftIcon />} {...a11yProps(6)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className={classes.tabpanel} value={value} index={0}>
                            <Grid item key='type' xs={12} md={4} style={{margin: 'auto'}}>
                                {/* <Typography id="size-range-slider" gutterBottom>{t("select_the_type_of_advertisements")}</Typography> */}
                                {/* <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                        <FormControlLabel value="sell" checked={types==='sell'} onChange={typeHandleChange} control={<Radio color="primary" />} label={t("properties")} />
                                        <FormControlLabel value="buy"  checked={types==='buy'} onChange={typeHandleChange} control={<Radio color="primary" />} label={t("clients")} />
                                    </RadioGroup>
                                </FormControl> */}
                                <DropDownArray name={t("select_the_type_of_advertisements")} options={typeOptions} select={types} handleSelect={typeHandleChange}  width='100%' />
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={1}>
                            <Grid container spacing={3} style={{justifyContent: 'center'}}>
                                <Grid item key='province' xs={12} md={3}>
                                    {provinceOptions && <DropDown name={t('province')} options={provinceOptions} select={provinceSelected} handleSelect={setProvinceSelected} width='100%' />}
                                </Grid>
                                <Grid item key='city' xs={12} md={3}>
                                    {cityOptions && <DropDown name={t('city')} options={cityOptions} select={citySelected} handleSelect={setCitySelected}  width='100%' />}
                                </Grid>
                                <Grid item key='neighborhood' xs={12} md={3}>
                                    {neighborhoodOptions && <DropDown name={t('neighborhood')} options={neighborhoodOptions} select={neighborhoodSelected} handleSelect={setNeighborhoodSelected}  width='100%' />}
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={2}>
                            {/* <Grid container spacing={3} style={{justifyContent: 'center'}}>
                                <Grid item key='apartment' xs={6} md={2}>
                                    <FormControlLabel control={<Checkbox checked={kindOfPropertyItem.apartment} onChange={KindOfPropertyHandleChange} name="apartment" color="primary" />} label={t("apartment")} />
                                </Grid>
                                <Grid item key='house' xs={6} md={2}>
                                    <FormControlLabel control={<Checkbox checked={kindOfPropertyItem.house} onChange={KindOfPropertyHandleChange} name="house" color="primary" />} label={t("house")} />
                                </Grid>
                                <Grid item key='store' xs={6} md={2}>
                                    <FormControlLabel control={<Checkbox checked={kindOfPropertyItem.store} onChange={KindOfPropertyHandleChange} name="store" color="primary" />} label={t("store")} />
                                </Grid>
                                <Grid item key='officialPlace' xs={6} md={2}>
                                    <FormControlLabel control={<Checkbox checked={kindOfPropertyItem['official-place']} onChange={KindOfPropertyHandleChange} name="official-place" color="primary" />} label={t("official_place")} />
                                </Grid>
                            </Grid> */}
                            <Grid item key='bed' xs={12} md={4} style={{margin: 'auto'}}>
                                <ShowCheckBox initialFeatures={initialKindOfPropertyItem} features={kindOfPropertyItem} setFeatures={KindOfPropertyHandleChange} placeholder='select_kind_of_property' />
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={3}>
                            <Grid item key='size' xs={12} md={4} style={{margin: 'auto'}}>
                                <DropDownArray name={t("select_the_range_of_size_based_on_meter")} options={sizeOptions} select={sizeValues} handleSelect={sizeHandleChange}  width='100%' />
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={4}>
                            <Grid item key='bed' xs={12} md={4} style={{margin: 'auto'}}>
                                <DropDownArray name={t("select_number_of_beds")} options={bedOptions} select={bedValues} handleSelect={bedHandleChange}  width='100%' />
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={5}>
                            <Grid item key='price' xs={12} md={4} style={{margin: 'auto'}}>
                                <DropDownArray name={t("select_the_range_of_price_based_on_Turkish_Lira")} options={priceOptions} select={priceValues} handleSelect={priceHandleChange}  width='100%' />
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={6}>
                            {/* <Grid container spacing={3}>
                                {Object.keys(features).map(f=>(
                                    <Grid item key={f} xs={6} md={2}>
                                        <FormControlLabel control={<Checkbox checked={features.f} onChange={featureHandleChange} name={f} color="primary" />} label={t(f)}/>
                                    </Grid>
                                ))}
                            </Grid> */}
                            <Grid item key='bed' xs={12} md={4} style={{margin: 'auto'}} className={classes.feature}>
                                <ShowCheckBox initialFeatures={initialFeatures} features={features} setFeatures={featureHandleChange} placeholder='select_features' />
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value} index={7}>
                            <div style={{display: 'flex'}}><Button variant="contained" color='primary' style={{margin: 'auto', minHeight: '50px'}} onClick={()=>setReset(true)} >{t('reset')} {t('filters')}</Button></div>
                        </TabPanel>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
