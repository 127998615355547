import axios from 'axios';
import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { UserContext } from '../../context/UserContext';
import RedirecModal from '../../templates/Modals/RedirecModal';
import { Loading } from '../../components';
import './userStyles.css'

const MyProfile = () => {

// Get translation
    const {t} = useTranslation()
// Get user Cookie from context
    const user = useContext(UserContext).cookies;
    const FETCH_URL = useContext(UserContext).FETCH_URL;
// remove cookie from context
    const removeUserCookie = useContext(UserContext).removeCookieData
// Set initial states
    const [userData, setUserData] = useState({})
    const [newUserData, setNewUserData] = useState({})
    const [errorText, setErrorText] = useState('');
    const [userUpdated, setUserUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(true)

// Fetching user data from DB
    const fetchUserInfo = async() => {
        if(user.loginData.email){
            const userEmail = user.loginData.email
            const fetchingUser = await fetch(`${FETCH_URL}/user/?email=${userEmail}`)
            const result = await fetchingUser.json()
            if(result){
                setUserData(result)
                setIsLoading(false)
            }
        }
    }
    useEffect(()=>{
        if(user.loginData.email)
            fetchUserInfo()
    },[])

// Collect password filed
    const newPassOnChange = (e) => {
        setNewUserData(prev=>({...prev, [e.target.id]: e.target.value}))
    }

// Checking form for errors
    const checkForm = (e) => {
        setErrorText('')
        if(Object.keys(newUserData).length>=3){
            let error = false;
            Object.keys(newUserData).map(f=>{
                if(newUserData[f]===''){
                    if(!error) setErrorText(t(`please_complete_field`, {f}))
                    error = true;
                }
            })
            if(error) return false;
            else return true;
        }else{
            setErrorText(t('please_complete_all_password_fields'));
            return false
        }
    }
    const checkLogicForm = (e) => {
        if(userData && newUserData){
            if(userData.password!==newUserData.currentPassword){
                setErrorText(t('your_current_password_is_wrong'))
                return false;
            }
            if(newUserData.newPassword.length<=5 || newUserData.confirmPassword.length<=5){
                setErrorText(t('your_new_password_must_be_at_least_6_character'))
                return false;
            }
            if(newUserData.newPassword!==newUserData.confirmPassword){
                setErrorText(t('your_new_passwords_are_not_matched'))
                return false;
            }
            return true;
        }
    }

// Updating user data
    const updateUserData = async(e) => {
        e.preventDefault()
        const validCheckForm = checkForm(e);
        if(validCheckForm){
            const validCheckLogicForm = checkLogicForm(e);
            if(validCheckLogicForm){
                const updatingUser = axios.put(`${FETCH_URL}/updateUser`, {email:userData.email, password:newUserData.newPassword})
                .then(response => {setUserUpdated(true)})
                .catch(err=>console.log(err))
            }
        }
    }
// Refresh page after updating
    useEffect(()=>{
        if(userUpdated){
            const refreshing = setInterval(()=>{
                window.location.reload();
                clearInterval(refreshing)
            },2000)
        }

    },[userUpdated])

// Logout user and remove cookies
    const logoutUser = e => {
        e.preventDefault()
        if(user.loginData){
            removeUserCookie('loginData', '/')
        }
    }
    
    return (
        <React.Fragment>
            {isLoading && <Loading />}
            {user.loginData ? 
            <div className='mainDiv'>
                {userUpdated && <RedirecModal msg='You password has updated successfully.' happening={'Please wait...'} />}
                <div className='fieldDiv'>
                    <form method='POST' onSubmit={e=>updateUserData(e)} key='myProfileForm' >
                        <label for='username'>{t(`username`)}</label>
                        <input id='username' type='text' value={userData.username} disabled />
                        <label for='email'>{t(`email`)}</label>
                        <input id='email' type='text' value={userData.email} disabled />
                        <div className='changePassDiv'>
                            <label for='curPass'>{t(`change_password`)}</label>
                            <input id='currentPassword' className='passInput' type='password' placeholder={t('current_password')} onChange={e=>newPassOnChange(e)} />
                            <input id='newPassword' className='passInput' type='password' placeholder={t('new_password')} onChange={e=>newPassOnChange(e)} />
                            <input id='confirmPassword' className='passInput' type='password' placeholder={t('confirm_password')} onChange={e=>newPassOnChange(e)} />
                        </div>
                        <div id='passError' className='passError'>{errorText}</div>
                        <div className='btnDiv'>
                            <button className='submitBtn' type='submit'>{t(`save_changes`)}</button>
                            <button className='logoutBtn' type='button' onClick={e=>logoutUser(e)}>{t(`logout`)}</button>
                        </div>
                    </form>
                </div>
            </div>
            : <Redirect to='/login' />}
        </React.Fragment>
    )
}

export default MyProfile
