import React, {Fragment, useState, useEffect, useContext} from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete';
import { UserContext } from '../../context/UserContext';
import useStyle from './style'

// const UpdateAdsFileUpload = ({uploadedTempFiles, setUploadedTempFiles, setUploadingTemp}) => {
const UpdateAdsFileUpload = ({hidedImagesFromThumbShow, setHidedImagesFromThumbShow, uploadedTempFiles, setUploadedTempFiles, setUploadingTemp}) => {

    const style = useStyle();

    const FETCH_URL = useContext(UserContext).FETCH_URL

    const {t} = useTranslation()

    const [fileError, setFileError] = useState([])

    const createRandomFileName = (name) => {
        const extension = name.split('.')
        return Date.now()+Math.floor(1000 + Math.random() * 9000)+'.'+extension[extension.length-1]
    }

    const imageAuth = (file=null) => {
        setFileError([])
        const fileTypes = ['image/jpeg', 'image/png']
        if(file){
            let errors = []
            if(file.size>310000) errors.push('image_size_cannot_be_more_than_300_KB')
            if(file.size<30000) errors.push('image_size_cannot_be_less_than_30_KB')
            if(!fileTypes.includes(file.type)) errors.push('it_is_not_an_image')

            setFileError(errors)
            if(errors.length) return false
            else return true
        }else return false
    }

    const uploadImageTemp = async e => {
        const file = e.target.files[0] || ''
        if(file){
            const authenticationedFile = imageAuth(file)
            if(authenticationedFile){
                setUploadingTemp(true)
                const newFilename = createRandomFileName(file.name)
                const formData = new FormData()
                formData.append('file', file, newFilename)
                try{
                    const res = await axios.post(`${FETCH_URL}/uploadFileTemp`, formData,  {headers: { "Content-Type": "multipart/form-data" }})
                    setUploadedTempFiles(prev=>([...prev, res.data]))
                } catch(error) {
                    console.log('file not uploaded ', error)
                }
                setUploadingTemp(false)
            }else console.log('file is not accepted')
        }else console.log('No file to upload in temp')
    }

    const deleteImageFromTemp = async (filepath) => {
        if(filepath){
            setUploadingTemp(true)
            try{
                const res = await axios.delete(`${FETCH_URL}/deleteTempFile`, {data: {filepath: filepath}})
                setUploadedTempFiles(prev=>(prev.filter(image=>(image.filepath !== res.data.filepath))))
            } catch (err) {
                console.log(err, 'server error')
            }
            setUploadingTemp(false)
        }else console.log('no file source in client')
    }

    // const deleteImageFromUpload = async (file) => {
    //     if(file){
    //         setUploadingTemp(true)
    //         try{
    //             const res = await axios.delete('/deleteUploadedFile', {data: {filename: file}})
    //             setUploadedTempFiles(prev=>(prev.filter(image=>(image.filepath !== res.data.filepath))))
    //         } catch (err) {
    //             console.log(err, 'server error')
    //         }
    //         setUploadingTemp(false)
    //     }else console.log('no file source in client')
    // }

    function hidingUploadedImage(image){
        if(hidedImagesFromThumbShow.includes(image)) setHidedImagesFromThumbShow(prev=>(prev.filter(img=>img!==image)))
    }

    const showImageThumb = () => {
        if(uploadedTempFiles.length || hidedImagesFromThumbShow.length){
            const imagesInUploadFolder = hidedImagesFromThumbShow.map(image=>(
                <Grid key={image} item md={4} sm={6} xs={6} className={style.gridItem}>
                    <img src={`${FETCH_URL}/User/uploaded/${image}`} />
                    <DeleteIcon className={style.removeBtn} onClick={()=>hidingUploadedImage(image)} />
                </Grid>
            ))
            const tempImageInTemp = uploadedTempFiles.map(image=>(
                <Grid key={image.filename} item md={4} sm={6} xs={6} className={style.gridItem}>
                    <img src={`${FETCH_URL}/User/${image.filepath}`} />
                    <DeleteIcon className={style.removeBtn} onClick={()=>deleteImageFromTemp(image.filepath)} />
                </Grid>
            ))
            const uploadedAndTempFile = imagesInUploadFolder.concat(tempImageInTemp)
            return uploadedAndTempFile
        }
    }

    const errorCreator = () => {
        if(fileError.length){
            return (
                <div className='errorsDiv' style={{width: '100%'}}>
                    {fileError.map(e=><div><i>{t(`${e}`)}</i></div>)}
                </div>
            )
        }
    }

    return (
        <Grid container className={style.gridContainer} spacing={0}>
            <div className={style.fileUploadTitle}>{t('upload_image')}{5-(uploadedTempFiles.length+hidedImagesFromThumbShow.length)}</div>
            <Grid item md={4} sm={6} xs={6} className={style.gridItem}>
                <div className={style.inputDiv}>
                    <input className={style.input} type="file" onChange={uploadImageTemp} disabled={(uploadedTempFiles.length+hidedImagesFromThumbShow.length)>=5} />
                    <p className={style.p}>{t('drag_or_click')}</p>
                </div>
            </Grid>
            {showImageThumb()}
            {errorCreator()}
        </Grid>
    )
}

export default UpdateAdsFileUpload
