import React, {useContext} from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography } from '@material-ui/core';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import HotelIcon from '@material-ui/icons/Hotel';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import useStyles from './styles'
import {adminContext} from '../../../context/adminContext'
import { UserContext } from '../../../context/UserContext'
import { useTranslation } from 'react-i18next';

const Product = ({ id, title, price, description, status, image=null, adsFiles, city, neighborhood, kindOfProperty, bed, date, size, user, admin, deleteAd, userFav, deleleFavItem}) => {
    const styles = useStyles()

    const {t} = useTranslation()

    const context = useContext(adminContext)
    const FETCH_URL = useContext(UserContext).FETCH_URL;
    // const FETCH_URL = 'http://localhost:4000';
    
    function showDetailsHandler(){ return }

    const showDate = `${new Date(date).getFullYear()}/${new Date(date).getMonth()}/${new Date(date).getDate()} - ${new Date(date).getHours()}:${new Date(date).getMinutes()}`    

    const updateAdStatusContext = context ? context.updateAdStatus : ''
    async function updateAdStatus(id, status){
        if(id && status) updateAdStatusContext(id, status)
    }

    function showAdStaus(adStatus){
        if(adStatus==='pending') return <div className={styles.badge} style={{backgroundColor: 'red'}}>{t(`pending`)}</div>
        if(adStatus==='active') return <div className={styles.badge} style={{backgroundColor: 'green'}}>{t(`active`)}</div>
        if(adStatus==='rejected') return <div className={styles.badge} style={{backgroundColor: '#484848'}}>{t(`rejected`)}</div>
        if(adStatus==='expired') return <div className={styles.badge} style={{backgroundColor: '#af0000'}}>{t(`expired`)}</div>
    }

    // function showAdStaus(adStatus){
    //     if(adStatus==='pending') return <div className={styles.badge} style={{backgroundColor: 'red'}}>pending</div>
    //     if(adStatus==='active') return <div className={styles.badge} style={{backgroundColor: 'green'}}>active</div>
    //     if(adStatus==='rejected') return <div className={styles.badge} style={{backgroundColor: '#484848'}}>rejected</div>
    //     if(adStatus==='expired') return <div className={styles.badge} style={{backgroundColor: '#af0000'}}>expired</div>
    // }

    const imageForCardMedia = (files) => {
        // if(files.length) return process.env.PUBLIC_URL+'/upload/'+files[0]
        // return process.env.PUBLIC_URL+'/upload/defaultAdsImage.jpg'
        if(files.length) return FETCH_URL+'/User/uploaded/'+files[0]
        return `${FETCH_URL}/User/uploaded/defaultAdsImage.jpg`
    }

    return (
        <Card className={styles.mainProductDiv}>
            <CardMedia className={styles.media} image={imageForCardMedia(adsFiles)} title={title}>{(user || admin) && showAdStaus(status)}</CardMedia>
            <CardContent>
                <div className={styles.cardContent}>
                    <Typography varient='h2' gutterBottom className={styles.adTitle}>{title}</Typography>
                    <Typography varient='h5'>{price>1 ? new Intl.NumberFormat({style: 'currency', currency: 'TRY'}).format(price)+t('TL') : t('agreement')}</Typography>
                </div>
                <Typography className={styles.description} varient='h5' color='textSecondary' >{city} - {neighborhood}</Typography>
                <div className={styles.iconDetails}>
                    <Typography varient='h5' color='textSecondary' ><HomeWorkIcon /> {t(`${kindOfProperty}`)}</Typography>
                    <Typography varient='h5' color='textSecondary' ><SquareFootIcon /> {size} m</Typography>
                    <Typography varient='h5' color='textSecondary' ><HotelIcon /> {bed} {t(`bedroom`)}</Typography>
                </div>
            </CardContent>
            <CardActions className={styles.cardActions} onClick={()=>{showDetailsHandler(id)}} >
                <Typography className={styles.description} varient='h5' color='textSecondary' >{showDate}</Typography>
                <Link className={styles.detailsBtn} to={`/AdDetails/${id}`} >{t(`details`)}</Link>
            </CardActions>
            {user && <div className={styles.userBtns}>
                <Button variant="contained" color='primary' component={Link} to={`/UpdateAds/${id}`} >{t(`modify`)}</Button>
                <Button variant="contained" color='secondary' onClick={()=>deleteAd(id)} >{t(`delete`)}</Button>
            </div>}
            {userFav && <div className={styles.userBtns}>
                <Button variant="contained" color='secondary' onClick={()=>deleleFavItem(id)} >{t(`remove_from_list`)}</Button>
            </div>}
            {admin && <div className={styles.userBtns}>
                {status==='pending' && <Button variant="contained" color='primary' onClick={()=>updateAdStatus(id, 'active')} >{t(`active`)}</Button>}
                {status==='pending' && <Button variant="contained" color='secondary' onClick={()=>updateAdStatus(id, 'rejected')} >{t(`reject`)}</Button>}
                {(status==='active' || status==='rejected') && <Button variant="contained" color='secondary' onClick={()=>updateAdStatus(id, 'pending')} >{t(`pending`)}</Button>}
            </div>}
        </Card>
    //     <Card className={styles.mainProductDiv}>
    //     <div testId='firstTest'>Hello</div>
    //     <CardMedia className={styles.media} image={imageForCardMedia(adsFiles)} title={title}>{(user || admin) && showAdStaus(status)}</CardMedia>
    //     <CardContent>
    //         <div className={styles.cardContent}>
    //             <Typography varient='h2' gutterBottom className={styles.adTitle}>{title}</Typography>
    //             <Typography varient='h5'>{price>1 ? new Intl.NumberFormat({style: 'currency', currency: 'TRY'}).format(price)+'TL' : 'agreement'}</Typography>
    //         </div>
    //         <Typography className={styles.description} varient='h5' color='textSecondary' >{city} - {neighborhood}</Typography>
    //         <div className={styles.iconDetails}>
    //             <Typography varient='h5' color='textSecondary' ><HomeWorkIcon /> {`${kindOfProperty}`}</Typography>
    //             <Typography varient='h5' color='textSecondary' ><SquareFootIcon /> {size} m</Typography>
    //             <Typography varient='h5' color='textSecondary' ><HotelIcon /> {bed} {`bedroom`}</Typography>
    //         </div>
    //     </CardContent>
    //     <CardActions className={styles.cardActions} onClick={()=>{showDetailsHandler(id)}} >
    //         <Typography className={styles.description} varient='h5' color='textSecondary' >{showDate}</Typography>
    //         <Link className={styles.detailsBtn} to={`/AdDetails/${id}`} >{`details`}</Link>
    //     </CardActions>
    //     {user && <div className={styles.userBtns}>
    //         <Button variant="contained" color='primary' component={Link} to={`/UpdateAds/${id}`} >{`modify`}</Button>
    //         <Button variant="contained" color='secondary' onClick={()=>deleteAd(id)} >{`delete`}</Button>
    //     </div>}
    //     {userFav && <div className={styles.userBtns}>
    //         <Button variant="contained" color='secondary' onClick={()=>deleleFavItem(id)} >{`remove_from_list`}</Button>
    //     </div>}
    //     {admin && <div className={styles.userBtns}>
    //         {status==='pending' && <Button variant="contained" color='primary' onClick={()=>updateAdStatus(id, 'active')} >{`active`}</Button>}
    //         {status==='pending' && <Button variant="contained" color='secondary' onClick={()=>updateAdStatus(id, 'rejected')} >{`reject`}</Button>}
    //         {(status==='active' || status==='rejected') && <Button variant="contained" color='secondary' onClick={()=>updateAdStatus(id, 'pending')} >{`pending`}</Button>}
    //     </div>}
    // </Card>
    )
}

export default Product