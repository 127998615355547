import React, {useState, useEffect, useContext} from 'react'
import { useHistory, Redirect } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { UserContext } from '../context/UserContext';
import FiledsTemplate from '../templates/FieldsTemplate/FieldsTemplate';

const Login = ({setUserInfo}) => {

    const [values, setValues] = useState({});
    const [status, setStatus] = useState([]);
    const [logicStatus, setLogicStatus] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [invalidEntries, setInvalidEntries] = useState({user: false, pass: false});

    let history = useHistory();
    const reqFields = ['Username', 'Password', 'Robot test'];
    const question = useContext(UserContext).question
    const userCookie = useContext(UserContext).cookies;
    const setCookieData = useContext(UserContext).setCookieData
    const FETCH_URL = useContext(UserContext).FETCH_URL

    async function checkForm(e){
        e.preventDefault();
        setStatus([]);
        setLogicStatus([]);
        setInvalidEntries({user: false, pass: false});
        const checkField = checkFields();
        if(checkField && isLogin===false){
            const checkLogicField = checkLogicFields()
            if(checkLogicField){
                setInvalidEntries({user: false, pass: false})
                const username = values.Username;
                const findUser = await fetch(`${FETCH_URL}/user/?username=${username}`)
                const result = await findUser.json()
                
                if(result && findUser.status===200){
                    if(result.password === values.Password){
                        setValues({}) // temp
                        setIsLogin(true)
                        const redirecting = setInterval(()=>{
                            setCookieData('loginData', {email: result.email, username: result.username, mobile: result.mobile, fav: result.fav})
                            clearInterval(redirecting)
                        }, 3000);       
                    }else{
                        setInvalidEntries({user: false, pass: true})
                    }
                }else{
                    setInvalidEntries({user: true, pass: false})
                    console.log('there is an error here')
                }
            }else return;
        }else return;
    }
// get data from fields
    function onChangeInput(e){
        setValues(prev=>({...prev ,[e.target.name] : e.target.value.trim()}));
    }
// check empty or wrong fields
    function checkFields(){
        let errors = [];
        reqFields.map(field=>{
            if(values[field]===undefined || values[field]==='')
                errors.push(field);
        });
        if(errors.length>0){
            setStatus(errors);
            return false
        }
        return true;
    }

// check fields for logic error
    function checkLogicFields(){
        let logicErrors = [];
        if(values){
            if(values.Username.length < 6) logicErrors.push('Username');
            if(values.Password.length < 7) logicErrors.push('Password')
            if(Number(values['Robot test']) !== question.answer) logicErrors.push('Robot test');
            if(logicErrors.length>0) {setLogicStatus(logicErrors); return false}
            else{return true}
        }
    }

    const fields = [
        {id: 1, fieldName: 'Username', fieldPlaceholder: 'enter_your_Username_please',type: 'text', required: '' },
        {id: 2, fieldName: 'Password', fieldPlaceholder: 'enter_your_password_please',type: 'password', required: '',},
        {id: 3, fieldName: `Robot test`, fieldPlaceholder: 'answer_the_question_please',type: 'text', required: ''}
    ];
    const name = 'Login'

    return (
        <div>
            {(fields && !(userCookie.loginData)) ? 
            <FiledsTemplate 
                name={name}
                fields={fields}
                checkForm={checkForm}
                onChange={onChangeInput}
                status={status}
                logicStatus={logicStatus}
                isLogin={isLogin}
                question={question.q}
                invalidEntries={invalidEntries}
            />
            :<Redirect to='/panel' />}
        </div>
    )
}

export default Login

