import React, { useEffect, useState } from 'react'
import './style.css'
import { DropDown } from '../../components'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const SortAds = ({setSort, setSortStatus, admin=false}) => {
    
    const priceOptions = ['ascending', 'descending']
    const dateOptions = ['newest', 'oldest']
    const sizeOptions = ['ascending', 'descending']
    const statusOptions = ['pending', 'rejected', 'active', 'expired']
    
    const [price ,setPrice] = useState('')
    const [date ,setDate] = useState('')
    const [size ,setSize] = useState('')
    const [status ,setStatus] = useState('')

    function setValuesForSort(data){
        if(data && data === 'newest') return -1
        if(data && data === 'oldest') return 1
        if(data && data === 'ascending') return 1
        if(data && data === 'descending') return -1
        else return ''
    }

    useEffect(()=>{
        setSort({
            date: setValuesForSort(date),
            price: setValuesForSort(price),
            size: setValuesForSort(size),
        })
    },[price,date,size])

    useEffect(()=>{
        setSortStatus(status)
    },[status])
    
    return (
        <div style={{marginBottom: '20px'}}>
            <div className='parentSortDiv'>Search and Sort Ads</div>
            <Grid className='sortMainDiv' container spacing={5} >
                {/* <Grid item md={3} xs={12}><TextField label="Search" placeholder='Enter here ...' style={{width: '100%'}} /></Grid> */}
                {admin && <Grid item md={2} xs={6}>
                    <DropDown 
                        options={statusOptions}
                        select={status}
                        handleSelect={setStatus}
                        name={`By status`}
                        width='100%'
                        sort={true}
                    />
                </Grid>}
                <Grid item md={2} xs={6}>
                    <DropDown 
                        options={dateOptions}
                        select={date}
                        handleSelect={setDate}
                        name={`By date`}
                        width='100%'
                        sort={true}
                    />
                </Grid>
                <Grid item md={2} xs={6}>
                    <DropDown 
                        options={priceOptions}
                        select={price}
                        handleSelect={setPrice}
                        name={`By price`}
                        width='100%'
                        sort={true}
                    />
                </Grid>
                <Grid item md={2} xs={6}>
                    <DropDown 
                        options={sizeOptions}
                        select={size}
                        handleSelect={setSize}
                        name={`By size`}
                        width='100%'
                        sort={true}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default SortAds
