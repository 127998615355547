import React from 'react';
import './styles.css'

const Loading = () => {
    return (
        <div style={{zIndex: 200, position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'white'}}>
            <svg width="100%" height="100%">
                <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots)"> </rect>
                <text x="50%" y="55%"  text-anchor="middle"  >
                TYM.ir
                </text>
            </svg>
        </div>
    )
}

export default Loading
